import { Typography } from 'shared/ui';
import { Login as LoginForm } from 'widgets/Session';
import { Register as RegisterForm } from 'features/Session';

import { TenderOffice } from 'widgets/Contact';
import { Intro, List } from 'widgets/ReferenceDocument';
import { List as TenderSchedule } from 'widgets/TenderSchedule';

import styles from './Login.module.css';

export const Login = () => {
  return (
    <div className={styles.container}>
      <Typography.Title size="medium">Тендеры</Typography.Title>
      <Intro />
      <List />
      <div className={styles.frame}>
        <LoginForm />
        <div className={styles['frame-delimiter']} />
        <RegisterForm />
      </div>
      <TenderSchedule />
      <div />
      <TenderOffice />
      <div />
    </div>
  );
};
