import { type PropsWithChildren } from 'react';

import { useMediaQuery } from 'shared/hooks';

import { Header } from '../components/Header';
import { Footer } from '../components/Footer';

import styles from './Private.module.css';

export const Private = ({ children }: PropsWithChildren<{}>) => {
  const isDesktop = useMediaQuery('desktop');

  const content = isDesktop ? (
    <>
      <div className={styles.container}>{children}</div>
    </>
  ) : (
    <>
      <div className={styles.content}>{children}</div>
    </>
  );
  return (
    <>
      <Header />
      {content}
      <Footer />
    </>
  );
};
