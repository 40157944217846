import { Outlet, Route, Routes } from 'react-router-dom';
import { Private as Layout } from '../layouts/Private';
import { Query as QueryProfile } from 'entities/User';
import { Query as QueryEntity } from 'entities/Entity';
import { RouterTabs } from 'shared/ui';

// import { Navigator } from './Navigator';
import { BreadCrumbs } from 'widgets/Navigation';

import { Auctions } from 'pages/Auctions';
import { Chat } from 'pages/Chat';
import { Contacts } from 'pages/Contacts';
import { Documents } from 'pages/Documents';
import { Entity } from 'pages/Entity';
import { EntityEditor } from 'pages/EntityEditor';
import { HelpDocuments } from 'pages/HelpDocuments';
import { Profile } from 'pages/Profile';
import { ProfileEditor } from 'pages/ProfileEditor';
import { Works } from 'pages/Works';
import { WorksEditor } from 'pages/WorksEditor';
import { Navigation } from 'pages/Navigation';

const ENTITY_TABS = [
  { caption: 'Сведения', href: 'info/' },
  { caption: 'Виды работ', href: 'works/' },
  { caption: 'Документы претендента', href: 'documents/' },
  { caption: 'Справочные документы', href: 'reference-documents/' },
];

export const Private = () => {
  return (
    <QueryProfile>
      <QueryEntity>
        <Layout>
          <Routes>
            <Route path="/" element={<Navigation />} />
            <Route path="/profile" element={<Outlet />}>
              <Route index element={<Profile />} />
              <Route path="edit" element={<ProfileEditor />} />
            </Route>
            <Route
              path="/entity/*"
              element={
                <RouterTabs
                  pattern="/entity/*"
                  title={<BreadCrumbs title="Моя организация / Аккредитация" />}
                  tabs={ENTITY_TABS}
                />
              }
            >
              <Route path="info" element={<Outlet />}>
                <Route index element={<Entity />} />
                <Route path="edit" element={<EntityEditor />} />
              </Route>
              <Route path="works" element={<Outlet />}>
                <Route index element={<Works />} />
                <Route path="edit" element={<WorksEditor />} />
              </Route>
              <Route path="documents" element={<Documents />} />
              <Route path="reference-documents" element={<HelpDocuments />} />
            </Route>
            <Route path="/auctions" element={<Auctions />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/chat" element={<Chat />} />
          </Routes>
        </Layout>
      </QueryEntity>
    </QueryProfile>
  );
};
