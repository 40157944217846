import { Typography } from 'shared/ui';
import { CONTACTS } from '../../../../constants';

import styles from './TenderOffice.module.css';

export const TenderOffice = () => {
  return (
    <div className={styles.container}>
      <Typography.Title size="xsmall">
        Контакты тендерного отдела:
      </Typography.Title>
      <div className={styles.contacts}>
        <Typography.Text view="primary-large">
          Телефон:{' '}
          <a
            href={`tel:${CONTACTS.TENDER_OFFICE.PHONE}`}
            className={styles.phone}
          >
            {CONTACTS.TENDER_OFFICE.FORMATTED}
          </a>
        </Typography.Text>
        <Typography.Text view="primary-large">
          Почта:{' '}
          <a
            href={`mailto:${CONTACTS.TENDER_OFFICE.EMAIL}`}
            className={styles.email}
          >
            {CONTACTS.TENDER_OFFICE.EMAIL}
          </a>
        </Typography.Text>
      </div>
    </div>
  );
};
