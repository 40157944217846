import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface UseEditorIfEmptyProps<T> {
  loading: boolean;
  value?: T;
  href: string;
  check: (value?: T) => boolean;
}

export const useEditorIfEmpty = <T>({
  loading,
  value,
  href,
  check,
}: UseEditorIfEmptyProps<T>) => {
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);
  const filled = useMemo(() => {
    if (loading) return true;
    if (loaded) return true;
    return check(value);
  }, [value, loaded, check, loading]);
  useEffect(() => {
    if (loaded) return;
    if (filled) setLoaded(true);
    else navigate(href);
  }, [loaded, filled, setLoaded, href, navigate]);
};
