import { useEffect, useState } from 'react';

export const Intro = () => {
  const [data, setData] = useState<string>();
  useEffect(() => {
    (async () => {
      const res = await fetch('/intro.html');
      if (res.ok) {
        const result = await res.text();
        setData(result);
      }
    })();
  }, []);
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: data || '' }} />
      <div>
        <p>
          Предлагаем ознакомиться с текущими тендерами Главстрой-Регионы на ЭТП
          b2b-center.ru
        </p>
        <p>
          <a href="https://www.b2b-center.ru/market/?f_keyword=филиал+ооо+главстрой-спб&searching=1&company_type=2&price_currency=0&date=1&trade=all#search-result">
            Тендеры на ЭТП b2b-center.ru
          </a>
        </p>
      </div>
    </div>
  );
};
