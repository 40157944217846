import {
  type Context,
  type PropsWithChildren,
  useEffect,
  useState,
} from 'react';

import { createStore, type Store } from '../../model/store';

export type MergerProc<Data> = (state: Data, value: Data) => Data;
export type Merger<Data> = 'merge' | 'replace' | MergerProc<Data>;

interface ProviderProps<Data extends object> {
  Context: Context<Store<Data>>;
  value: Data;
  merger?: Merger<Data>;
}

const MERGER_MERGE = <Data,>(state: Data, value: Data) => ({
  ...state,
  ...value,
});

const MERGER_REPLACE = <Data,>(state: Data, value: Data) => value;

const getMerger = <Data,>(merger: Merger<Data>): MergerProc<Data> => {
  if (merger === 'merge') return MERGER_MERGE;
  if (merger === 'replace') return MERGER_REPLACE;
  if (typeof merger === 'function') return merger;
  throw new TypeError('Неизвестный мержер - ' + merger);
};

/**
 * Провайдер, предоставляющий стор для входящих данных
 * @property Context - react контекст
 * @property value - данные для стора
 * @property merger - кастомное обновление данных стора от входящих данных
 */
export const Provider = <Data extends object>({
  Context,
  value,
  merger = 'merge',
  children,
}: PropsWithChildren<ProviderProps<Data>>) => {
  // стор создается один раз при маунте с переданными данными
  const [store] = useState(() => createStore(value));
  // обновление стора при изменении данных
  useEffect(() => {
    store.setState((state) =>
      state === value ? state : getMerger(merger)(state, value)
    );
  }, [value, store, merger]);
  // данные контекста - useStore(selector, equalityFn) - ререндеры дочерних компонентов будут по селектору
  return <Context.Provider value={store}>{children}</Context.Provider>;
};
