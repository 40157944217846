import { Content, Typography } from 'shared/ui';
import { RestoreConfirm as Form } from 'features/Session';

export const RestoreConfirm = () => {
  return (
    <Content mobile>
      <Typography.Title size="medium" weight="normal">
        Восстановление пароля
      </Typography.Title>
      <Form successUrl="/" />
    </Content>
  );
};
