import type { TenderSchedule } from 'shared/types';

export interface TenderScheduleSlice {
  loading: boolean;
  value: TenderSchedule[];
}

export const EMPTY_SLICE: TenderScheduleSlice = {
  loading: true,
  value: [],
};

export interface ScheduleIndex {
  type: string;
  id: string;
  caption: string;
  items: TenderSchedule[];
}
