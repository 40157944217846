import { useMemo } from 'react';
import { Work } from 'shared/types';

export type WorksIndex = Record<string, Work>;

export const useWorksIndex = (source?: Work[]) => {
  return useMemo(() => {
    if (!source) return {};
    return source.reduce((result, item) => {
      if (result[item.id]) console.log('duplicated!', item);
      result[item.id] = item;
      return result;
    }, {} as WorksIndex);
  }, [source]);
};
