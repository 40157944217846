import { type PropsWithChildren, useEffect, useMemo, useState } from 'react';

import { localStorage } from 'shared/lib/storages';
import { api } from 'shared/api';

import { EMPTY_SESSION, SessionContext, type SessionData } from '../../model';

const KEY = 'gs.tenders.session';

const INITIAL_SESSION = localStorage.getItem<SessionData>(KEY, EMPTY_SESSION);
api.token = INITIAL_SESSION.token;

export const Query = ({ children }: PropsWithChildren<{}>) => {
  const [session, setSession] = useState(INITIAL_SESSION);
  const value = useMemo(() => ({ session, setSession }), [session, setSession]);
  useEffect(() => {
    api.token = session.token;
    if (session !== INITIAL_SESSION) {
      console.log('сохранение сессии');
      localStorage.setItem(KEY, session);
    }
  }, [session]);
  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};
