import {
  cloneElement,
  useMemo,
  Fragment,
  type ReactNode,
  type ReactElement,
  // ReactComponentElement,
} from 'react';
import type { Document } from 'shared/types';
import { Content, Frame, List as ListControl, Typography } from 'shared/ui';

import { useGroupedDocuments, type DocumentGroup } from '../../hooks';
import { Item } from '../Item';

import styles from './List.module.css';

interface ListProps {
  loading: boolean;
  items?: Document[];
  groupIds?: string[];
  onRefresh?: () => void;
  onGetCaption?: (id: string) => string | undefined;
  onGetStatus?: (id: string) => string | undefined;
  action?: ReactNode;
}

export const List = ({
  loading,
  items,
  groupIds,
  onRefresh,
  action,
  onGetCaption,
  onGetStatus,
}: ListProps) => {
  const groupsSource = useGroupedDocuments(items, groupIds);
  const groups = useMemo(() => {
    return groupsSource?.map((item) => {
      const result = { ...item } as DocumentGroup;
      const status = onGetStatus && item.id ? onGetStatus(item.id) : undefined;
      result.canApprove = status === 'not_assigned' && result.canApprove;
      if (status === 'checking' || status === 'requested') {
        result.inProgress = true;
        result.canApprove = false;
      }
      return result;
    });
  }, [groupsSource, onGetStatus]);
  return (
    <Content inplace className={styles.container}>
      {groups?.map((group, i) => (
        <Fragment key={i}>
          <Frame key={i} className={styles.group}>
            <Typography.Title size="xsmall">
              {group.caption ||
                (onGetCaption && group.id
                  ? onGetCaption(group.id)
                  : 'Неизвестно')}
            </Typography.Title>
            <ListControl>
              {group.items.map((item, i) => (
                <Item
                  id={item.id}
                  name={item.name}
                  approvalEnds={item.approval_ends}
                  rejectedReason={item.reject_reason}
                  docId={item.template_file_id}
                  fileId={item.file_id}
                  fileName={item.file_name}
                  size={item.max_size}
                  format={item.format}
                  status={item.status}
                  required={
                    (group.id &&
                      item.condition_sets_mandatory?.includes(group.id)) ||
                    item.condition_sets_mandatory?.some(
                      (item) => !item || item.length < 7
                    )
                  }
                  onRefresh={onRefresh}
                  key={i}
                />
              ))}
            </ListControl>
          </Frame>
          {group.inProgress ? (
            <div className={styles.actions}>
              <div className={styles.status}>Ожидает проверки</div>
            </div>
          ) : null}
          {action && group.canApprove ? (
            <div className={styles.actions}>
              {typeof action === 'object' &&
                cloneElement(action as ReactElement, {
                  ...((action as ReactElement).props || {}),
                  workId: group.id,
                })}
            </div>
          ) : null}
        </Fragment>
      ))}
    </Content>
  );
};
