import { useContext } from 'react';
import { Context, ReferenceDocumentSlice } from '../model';

export { shallow } from 'shared/data';

export const useStore = <T>(
  selector: (state: ReferenceDocumentSlice) => T,
  equalityFn?: (a1: T, a2: T) => boolean
) => {
  const useStore = useContext(Context);
  return useStore(selector);
};

export const useReferenceDocuments = () => {
  const store = useStore((state) => state);
  return store;
};
