import { ButtonHTMLAttributes, AnchorHTMLAttributes } from 'react';
import clsx from 'clsx';

import { Link } from 'react-router-dom';

import { Spinner } from '../Spinner';

import styles from './Button.module.css';

interface OwnButtonProps {
  color?: 'primary' | 'transparent';
  block?: boolean;
  loading?: boolean;
  href?: string;
}

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement> &
  OwnButtonProps;

export const Button = ({
  className,
  href,
  color,
  block,
  loading,
  children,
  ...props
}: ButtonProps) => {
  const Component = href ? Link : 'button';
  return (
    <Component
      to={href!}
      className={clsx(
        styles.container,
        color ? styles[`color_${color}`] : null,
        block ? styles.is_block : null,
        loading ? styles.is_loading : null,
        className
      )}
      {...props}
    >
      {children}
      <Spinner active={loading} />
    </Component>
  );
};
