import { FormEventHandler } from 'react';
import { type Work } from 'shared/types';
import { Button, Content, Typography } from 'shared/ui';
import { List } from '../List';

import styles from './Editor.module.css';

interface EditorProps {
  loading?: boolean;
  error?: string;
  posting?: boolean;
  value?: Work[];
  onSubmit: FormEventHandler<HTMLFormElement>;
  onToggle: (id: string) => void;
}

export const Editor = ({
  error,
  posting,
  loading,
  value,
  onSubmit,
  onToggle,
}: EditorProps) => {
  return (
    <form onSubmit={onSubmit}>
      <Content inplace>
        <List loading={loading} items={value} onToggle={onToggle} />
        {!loading ? (
          <div className={styles.actions}>
            <Button color="primary" type="submit" loading={posting}>
              Сохранить
            </Button>
          </div>
        ) : null}
        {error ? (
          <Typography.Text color="error">{error}</Typography.Text>
        ) : null}
      </Content>
    </form>
  );
};
