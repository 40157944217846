import {
  useCallback,
  useRef,
  type FormEventHandler,
  type ChangeEventHandler,
  type ReactNode,
} from 'react';
import clsx from 'clsx';

import { TextArea } from 'shared/ui/components/TextArea';
import { SendButton } from '../SendButton';

import styles from './InputBar.module.css';

interface InputBarProps {
  className?: string;
  value?: string;
  loading?: boolean;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onSubmit?: (value: string) => void;
  onReset?: () => void;
  leftAddons?: ReactNode;
  rightAddons?: ReactNode;
}

export const InputBar = ({
  className,
  value,
  onChange,
  onSubmit,
  onReset,
}: InputBarProps) => {
  const refValue = useRef<string | undefined>(value);
  refValue.current = value;
  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      console.log('handleSubmit');
      e.preventDefault();
      e.stopPropagation();
      refValue.current && onSubmit && onSubmit(refValue.current);
      onReset && onReset();
    },
    [onSubmit, refValue, onReset]
  );
  return (
    <form className={clsx(styles.container, className)} onSubmit={handleSubmit}>
      <div className={styles.addons}></div>
      <TextArea
        containerClassName={styles.input}
        value={value}
        onChange={onChange}
        placeholder="Введите текст сообщения"
        maxLength={30000}
        autoSubmit
      />
      <div className={styles.addons}>
        <SendButton />
      </div>
    </form>
  );
};
