import clsx from 'clsx';
import { Checkbox } from 'shared/ui';
import folderIcon from 'shared/icons/folder_open.svg';
import folderClosed from 'shared/icons/folder.svg';

import styles from './Item.module.css';

interface ItemProps {
  className?: string;
  id: string;
  closed?: boolean;
  name: string;
  level: number;
  selected?: boolean;
  folder?: boolean;
  onToggle?: (id: string) => void;
}

const LEVEL_WIDTH = 20;

export const Item = ({
  className,
  id,
  closed,
  level,
  name,
  selected,
  folder,
  onToggle,
}: ItemProps) => {
  const levelValue = level ? (level * LEVEL_WIDTH).toString() + 'px' : 0;
  const gapStyle = { width: levelValue };
  return (
    <div
      className={clsx(styles.container, className)}
      onClick={() => onToggle && onToggle(id)}
    >
      <div className={styles.gap} style={gapStyle} />
      <div className={styles.select}>
        {onToggle && !folder ? (
          <Checkbox checked={selected} onClick={() => {}} />
        ) : null}
        {folder ? (
          <img
            className={styles.icon}
            src={closed ? folderClosed : folderIcon}
            alt=""
          />
        ) : null}
      </div>
      {name}
    </div>
  );
};
