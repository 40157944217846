import { type PropsWithChildren, type ReactNode, useEffect } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import { List } from '../List';

import { useState } from './useState';

import styles from './SubMenu.module.css';

interface SubMenuProps {
  className?: string;
  caption: ReactNode;
}

export const SubMenu = ({
  className,
  caption,
  children,
}: PropsWithChildren<SubMenuProps>) => {
  const { open, setOpen, setClose, setCloseNow } = useState();
  const location = useLocation();
  useEffect(() => {
    setCloseNow();
  }, [location, setCloseNow]);

  return (
    <div
      className={clsx(styles.container, open ? styles.open : null, className)}
      onMouseEnter={setOpen}
      onMouseLeave={setClose}
    >
      {caption}
      <List className={styles.list} orientation="horizontal">
        {children}
      </List>
    </div>
  );
};
