import { ChangeEventHandler, useCallback } from 'react';
import { InputBar as Control, useSubmit } from 'entities/Chat';
import { nowText } from 'shared/lib/date';

interface InputBarProps {
  className?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onReset?: () => void;
}

export const InputBar = ({
  className,
  value,
  onChange,
  onReset,
}: InputBarProps) => {
  const { loading, perform } = useSubmit();
  const handleSubmit = useCallback(
    (value: string) => {
      const data = {
        text: value,
        date: nowText(),
      };
      perform(data);
    },
    [perform]
  );
  return (
    <Control
      className={className}
      value={value}
      onChange={onChange}
      onSubmit={handleSubmit}
      onReset={onReset}
      loading={loading}
    />
  );
};
