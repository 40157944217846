import { FormEventHandler, useCallback } from 'react';
import clsx from 'clsx';
import { Button, Content, Field, Typography } from 'shared/ui';
import type { RegisterData } from 'shared/types';
import styles from './Register.module.css';

interface RegisterProps {
  loading?: boolean;
  error?: string;
  sent?: boolean;
  onSubmit: (data: RegisterData) => void;
}

interface Elements {
  login: HTMLInputElement;
  hash: HTMLInputElement;
  email: HTMLInputElement;
}

export const Register = ({ loading, error, sent, onSubmit }: RegisterProps) => {
  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const { login, email, hash } = e.currentTarget
        .elements as unknown as Elements;
      const data: RegisterData = {
        login: login.value,
        email: email.value,
        hash: hash.value,
      };
      onSubmit(data);
    },
    [onSubmit]
  );
  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <Content className={styles.content} mobile>
        <Typography.Title size="medium" weight="normal">
          Зарегистрировать аккаунт
        </Typography.Title>
        <div />
        <Field
          name="login"
          caption="Логин"
          color={error ? 'error' : undefined}
          autoFocus
        />
        <Field name="email" caption="Электронная почта" />
        <Field name="hash" caption="Пароль" type="password" />
        <div />
        <Button color="primary" type="submit" loading={loading}>
          Создать аккаунт
        </Button>
        {error ? (
          <div>
            <Typography.Text color="error">{error}</Typography.Text>
          </div>
        ) : null}
        <div className={clsx(styles.success, sent ? styles.active : null)}>
          Для продолжения регистрации проверьте электронную почту
        </div>
      </Content>
    </form>
  );
};
