import type { PropsWithChildren } from 'react';

import { Query, Private, Public } from 'entities/Session';

const SessionPart = ({ children }: PropsWithChildren<{}>) => {
  return <Query>{children}</Query>;
};

type SessionComponent = typeof SessionPart & {
  Private: typeof Private;
  Public: typeof Public;
};

export const Session = SessionPart as SessionComponent;
Session.Private = Private;
Session.Public = Public;
