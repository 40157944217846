import type { PropsWithChildren } from 'react';

import { Query as Store } from 'shared/data';
import { useQuery } from '../../hooks';
import { Context } from '../../model';

export const Query = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Store Context={Context} useQuery={useQuery} params={undefined}>
      {children}
    </Store>
  );
};
