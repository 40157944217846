import { type ReactNode, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import { Link, Outlet, useMatch, useNavigate } from 'react-router-dom';
import { List } from '../List';
import { Typography } from '../Typography';

import styles from './RouterTabs.module.css';

interface RouterTab {
  caption: string;
  href: string;
}

interface RouterTabsProps {
  title?: ReactNode;
  tabs: RouterTab[];
  pattern: string;
}

const extractSlug = (url: string) => url.split('/').filter(Boolean)[0];

export const RouterTabs = ({ title, tabs, pattern }: RouterTabsProps) => {
  const match = useMatch(pattern);
  const navigate = useNavigate();
  const currentTab = useMemo(() => {
    if (!match) return;
    const neededSlug = match.params['*']
      ? extractSlug(match.params['*'])
      : undefined;
    if (!neededSlug) return;
    return tabs.find((item) => extractSlug(item.href) === neededSlug);
  }, [tabs, match]);
  useEffect(() => {
    if (!currentTab) {
      const url = pattern.replace('*', tabs[0].href);
      console.log('target url', url);
      navigate(url, { replace: true });
    }
  }, [currentTab, tabs, navigate, pattern]);
  return (
    <>
      {title}
      <List className={styles.container} orientation="horizontal">
        {tabs.map((item) => (
          <Link
            className={clsx(
              styles.link,
              item === currentTab ? styles.active : null
            )}
            to={item.href}
            replace
            key={item.href}
          >
            <Typography.Title size="xsmall">{item.caption}</Typography.Title>
          </Link>
        ))}
      </List>
      <Outlet />
    </>
  );
};
