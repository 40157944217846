import type { ReferenceDocument } from 'shared/types';

export interface ReferenceDocumentSlice {
  loading: boolean;
  value: ReferenceDocument[];
}

export const EMPTY_SLICE: ReferenceDocumentSlice = {
  loading: true,
  value: [],
};
