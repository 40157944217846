import { Button } from 'shared/ui';

interface LoginProps {
  color?: 'primary';
  block?: boolean;
  loading?: boolean;
}

export const Login = ({ color, block, loading }: LoginProps) => {
  return (
    <Button type="submit" color={color} block={block} loading={loading}>
      Войти
    </Button>
  );
};
