import { Children, LegacyRef, type PropsWithChildren } from 'react';
import clsx from 'clsx';

import styles from './List.module.css';

type Orientation = 'vertical' | 'horizontal' | 'screen';

interface ListProps {
  className?: string;
  itemClassName?: string;
  orientation?: Orientation;
  onClick?: () => void;
  containerRef?: LegacyRef<HTMLUListElement>;
}

export const List = ({
  className,
  itemClassName,
  orientation = 'vertical',
  onClick,
  containerRef,
  children,
}: PropsWithChildren<ListProps>) => {
  return (
    <ul
      className={clsx(
        styles.container,
        orientation ? styles[`orientation_${orientation}`] : null,
        className
      )}
      onClick={onClick}
      ref={containerRef}
    >
      {Children.map(children, (item, i) => (
        <li className={clsx(styles.item, itemClassName)} key={i}>
          {item}
        </li>
      ))}
    </ul>
  );
};
