import { type ReactNode } from 'react';
import type { Entity as EntityType } from 'shared/types';

import { Typography } from 'shared/ui';

import styles from './Entity.module.css';

interface EntityProps {
  loading: boolean;
  value: EntityType;
  action: ReactNode;
}

export const Entity = ({ loading, value, action }: EntityProps) => {
  return (
    <>
      <div>
        <Typography.Label>ИНН</Typography.Label>
        <Typography.Text>{value.inn}</Typography.Text>
      </div>
      <div>
        <Typography.Label>Полное наименование</Typography.Label>
        <Typography.Text>{value.full_name}</Typography.Text>
      </div>
      <div>
        <Typography.Label>Краткое наименование</Typography.Label>
        <Typography.Text>{value.short_name}</Typography.Text>
      </div>
      <div>
        <Typography.Label>КПП</Typography.Label>
        <Typography.Text>{value.kpp}</Typography.Text>
      </div>
      <div>
        <Typography.Label>ОГРН</Typography.Label>
        <Typography.Text>{value.ogrn}</Typography.Text>
      </div>
      <div>
        <Typography.Label>Юридический адрес</Typography.Label>
        <Typography.Text>{value.legal_addr}</Typography.Text>
      </div>
      <div>
        <Typography.Label>Адрес исполнительного органа</Typography.Label>
        <Typography.Text>{value.post_addr}</Typography.Text>
      </div>
      <div>
        <Typography.Label>Сайт</Typography.Label>
        <Typography.Text>{value.homepage}</Typography.Text>
      </div>
      <div>
        <Typography.Label>Телефон</Typography.Label>
        <Typography.Text>{value.phone}</Typography.Text>
      </div>
      <div>
        <Typography.Label>ФИО руководителя</Typography.Label>
        <Typography.Text>{value.head}</Typography.Text>
      </div>
      <div className={styles.actions}>{action}</div>
    </>
  );
};
