import { useCallback, useEffect, useState } from 'react';

const QUERIES: Record<string, string> = {
  mobile: '(max-width: 1240px)',
  desktop: '(min-width: 1241px)',
};

type ChangeHandler = (this: MediaQueryList, ev: MediaQueryListEvent) => any;

const CHANGE = 'change';

export const useMediaQuery = (query: string) => {
  if (!!QUERIES[query]) query = QUERIES[query];

  const [matches, setMatches] = useState(
    () => window.matchMedia(query).matches
  );

  const handleChange = useCallback<ChangeHandler>((e) => {
    setMatches(e.matches);
  }, []);

  useEffect(() => {
    const mq = window.matchMedia(query);
    mq.addEventListener(CHANGE, handleChange);
    //
    return () => {
      mq.removeEventListener(CHANGE, handleChange);
    };
  }, [query, handleChange]);
  //
  return matches;
};
