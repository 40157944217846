import { useEffect, useMemo, useState } from 'react';

export type TreeItemIndex<T, Key extends string> = Record<Key, T[]>;

interface UseTreeOptions<
  T,
  KeyField extends string,
  ParentKeyField extends string
> {
  items?: T[];
  keyField: KeyField;
  parentKeyField: ParentKeyField;
}

interface TreeItem<T> {
  value?: T;
  children?: TreeItem<T>[];
}

export const getItemsSlice = <T>(
  index: TreeItemIndex<T, string>,
  keyField: string,
  parentKeyField: string,
  parent?: T
) => {
  const parentKey = parent ? parent[keyField] : '$root';
  const childrenItems = index[parentKey] || [];
  // console.log('parentKey', parentKeyField, index, index[parentKey]);
  return childrenItems
    ? childrenItems.map(
        (item) =>
          ({
            value: item,
            children: getItemsSlice(index, keyField, parentKeyField, item),
          } as TreeItem<T>)
      )
    : undefined;
};

export const buildTree = <T>(
  items: T[],
  keyField: string,
  parentKeyField: string
) => {
  const index: TreeItemIndex<T, string> = {};
  for (let k = 0; k < items.length; k++) {
    const item = items[k];
    const parentKey = item[parentKeyField] || '$root';
    if (!index[parentKey]) index[parentKey] = [];
    index[parentKey].push(item);
  }
  return items && items.length
    ? ({
        value: undefined,
        children: getItemsSlice(index, keyField, parentKeyField),
      } as TreeItem<T>)
    : ({} as TreeItem<T>);
};

export const useTree = <
  T,
  KeyField extends string,
  ParentKeyField extends string
>({
  items,
  keyField,
  parentKeyField,
}: UseTreeOptions<T, KeyField, ParentKeyField>) => {
  const [source, setSource] = useState<T[]>([]);
  useEffect(() => {
    setSource([...(items || [])] as T[]);
  }, [items]);
  const nodes = useMemo(
    () => buildTree(source || [], keyField, parentKeyField),
    [source, keyField, parentKeyField]
  );
  return { nodes };
};
