import { PropsWithChildren } from 'react';
import clsx from 'clsx';

import styles from './Content.module.css';

interface ContentProps {
  className?: string;
  mobile?: boolean;
  inplace?: boolean;
}

export const Content = ({
  className,
  mobile,
  inplace,
  children,
}: PropsWithChildren<ContentProps>) => {
  return (
    <section
      className={clsx(
        styles.container,
        mobile ? styles.view_mobile : null,
        inplace ? styles.view_inplace : null,
        className
      )}
    >
      {children}
    </section>
  );
};
