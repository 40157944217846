import { Link } from 'react-router-dom';
import { Login } from 'entities/User';
import { Action } from 'shared/ui';
import { useLogout } from 'features/Session/hooks';

import styles from './HeaderElement.module.css';

export const HeaderElement = () => {
  const { loading, handleLogout } = useLogout();
  return (
    <div className={styles.container}>
      <Link to="/profile" className={styles.content}>
        <Login />
      </Link>
      <Action icon="logout" loading={loading} onClick={handleLogout} />
    </div>
  );
};
