import { useCallback, useEffect, useMemo, useState } from 'react';
import { clsx } from 'clsx';
import { useIndexes } from 'entities/TenderSchedule/hooks';
import { Content, List, Typography } from 'shared/ui';

import styles from './Items.module.css';

export const Items = () => {
  const { constrGroups, constrs, parents } = useIndexes();
  const [activeTab, setActiveTab] = useState('');
  const groups = useMemo(() => {
    return Object.keys(parents).map((key) => parents[key]);
  }, [parents]);

  useEffect(() => {
    setActiveTab((current) => {
      if (current === '' && groups.length) return groups[0].id;
      return current;
    });
  }, [groups, setActiveTab]);

  const subGroups = useMemo(() => {
    return Object.keys(constrGroups)
      .filter((key) => {
        const group = constrGroups[key];
        const item = group.items?.[0];
        return (
          item?.constr_group_parent_id === activeTab ||
          item?.constr_group_id === activeTab
        );
      })
      .map((key) => constrGroups[key]);
  }, [constrGroups, activeTab]);
  const objects = useMemo(() => {
    return subGroups.map((item) => ({
      id: item.id,
      caption: item.caption,
      type: item.type,
      groups: Object.keys(constrs)
        .filter((key) => {
          const constr = constrs[key];
          return constr.items?.[0].constr_group_id === item.id;
        })
        .map((key) => constrs[key]),
    }));
  }, [subGroups, constrs]);
  const [openedIds, setOpenedIds] = useState(() => new Set<string>());
  const toggleOpen = useCallback((id: string) => {
    setOpenedIds((current) => {
      const result = new Set(current);
      if (result.has(id)) result.delete(id);
      else result.add(id);
      return result;
    });
  }, []);

  return (
    <Content className={styles.container}>
      <Typography.Title size="medium">
        Графики проведения тендеров
      </Typography.Title>
      <List orientation="horizontal" className={styles.groups}>
        {groups.map((item) => (
          <div
            key={item.id}
            className={clsx(
              styles.item,
              item.id === activeTab ? styles.active : null
            )}
            onClick={() => setActiveTab(item.id)}
          >
            <Typography.Title size="xsmall">{item.caption}</Typography.Title>
          </div>
        ))}
      </List>
      {!objects.length ? <div>Тендеры не найдены</div> : null}
      {objects.map((item) => (
        <div className={styles.group} key={item.id}>
          <Typography.Title
            className={styles.toggleContainer}
            size="xsmall"
            onClick={() => toggleOpen(item.id)}
            block
          >
            <span
              className={clsx(
                styles.toggler,
                !openedIds.has(item.id) ? styles.togglerDown : null
              )}
            >
              ›
            </span>
            <span>График проведения тендеров по объекту {item.caption}</span>
          </Typography.Title>
          {!openedIds.has(item.id)
            ? item.groups.map((item) => (
                <>
                  <Typography.Text
                    size="xsmall"
                    className={styles.toggleContainer}
                    onClick={() => toggleOpen(item.id)}
                    block
                  >
                    <span
                      className={clsx(
                        styles.toggler,
                        !openedIds.has(item.id) ? styles.togglerDown : null
                      )}
                    >
                      ›
                    </span>
                    {item.caption}
                  </Typography.Text>
                  {!openedIds.has(item.id) ? (
                    <table className={styles.grid}>
                      <thead>
                        <tr>
                          <th>Товарная группа</th>
                          <th>Вид работ (предмет тендера)</th>
                          <th>Планируемая дата начала тендера</th>
                          <th>Планируемая дата окончания тендера</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item.items.map((item, i) => (
                          <tr key={i}>
                            <td>{item.tender_group}</td>
                            <td>{item.tender}</td>
                            <td>{item.start_date}</td>
                            <td>{item.end_date}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : null}
                </>
              ))
            : null}
        </div>
      ))}
    </Content>
  );
};
