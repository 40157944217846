import { useEffect } from 'react';
import { api } from 'shared/api';
import { useQuery } from 'shared/hooks';

const params: unknown = undefined;

export const useWorks = () => {
  const result = useQuery(api.getWorks);
  const { perform } = result;
  useEffect(() => {
    perform(params);
  }, [perform]);
  return result;
};
