import { useMemo } from 'react';
import type { Document } from 'shared/types';

export interface DocumentGroup {
  caption?: string;
  id?: string;
  items: Document[];
  canApprove: boolean;
  inProgress: boolean;
  rejected: boolean;
}

type DocumentIndex = Record<string, Document[]>;

export const buildGroupItems = (workId: string, index?: DocumentIndex) => {
  const ids = {} as Record<string, boolean>;
  return ['all', 'ip', 'org', workId].reduce((result, workId) => {
    const list = index?.[workId] || [];
    const docs = list.reduce((result, doc) => {
      if (!ids[doc.id]) {
        ids[doc.id] = true;
        result.push(doc);
      }
      return result;
    }, [] as Document[]);
    result = [...result, ...docs];
    return result;
  }, [] as Document[]);
};

const getIsRejected = (items: Document[]) =>
  !!items.find((item) => item.status === 'rejected');

const getIsChecking = (items: Document[]) =>
  !!items.find((item) => item.status === 'checking');

const getInProgress = (items: Document[]) =>
  !!items.find(
    (item) => item.status === 'rejected' || item.status === 'not_present'
  ) && getIsChecking(items);

const getCanApprove = (items: Document[]) =>
  !getIsRejected(items) &&
  !getIsChecking(items) &&
  !!items.find((item) => item.status === 'applied') &&
  !items.find((item) => item.status === 'not_present');

export const useGroupedDocuments = (
  items?: Document[],
  groupIds?: string[]
) => {
  return useMemo(() => {
    const index = items?.reduce((result, item) => {
      [
        ...(item.condition_sets_mandatory || []),
        ...(item.condition_sets_non_mandatory || []),
      ].forEach((id) => {
        if (!result[id]) result[id] = [];
        result[id].push(item);
      });
      return result;
    }, {} as DocumentIndex);
    return groupIds?.map((workId) => {
      const result = {
        id: workId,
        items: buildGroupItems(workId, index),
      } as DocumentGroup;
      result.canApprove = getCanApprove(result.items);
      result.inProgress = getInProgress(result.items);
      result.rejected = getIsRejected(result.items);
      return result;
    });
  }, [items, groupIds]);
};
