import { useEffect, useMemo } from 'react';
import { api } from 'shared/api';
import { useQuery } from 'shared/hooks';
import { type EntitySlice, EMPTY_SLICE } from '../model';

export const useEntityQuery = () => {
  const { loading, value, perform } = useQuery(api.getEntity);
  useEffect(() => {
    perform(undefined as unknown);
  }, [perform]);
  return useMemo(
    () => ({
      value: {
        loading,
        value: value || EMPTY_SLICE.value,
      } as EntitySlice,
      refresh: perform,
    }),
    [loading, value, perform]
  );
};
