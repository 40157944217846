import { useCallback, useState } from 'react';

export interface UseQueryResult<Response, Request> {
  loading: boolean;
  perform: (data: Request) => void;
  value?: Response;
}

export type Handler<Response, Request> = (
  request: Request
) => Promise<Response>;

export const useQuery = <Response, Request>(
  handler: Handler<Response, Request>
) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<Response>();
  const [error, setError] = useState<string>();
  const perform = useCallback(
    (data: Request) => {
      setLoading(true);
      setError(undefined);
      return (async () => {
        try {
          const value = await handler(data);
          setValue(value);
          setLoading(false);
          return value as Response;
        } catch (e) {
          if (e instanceof Error) {
            setError(e.message);
          }
          setLoading(false);
        }
      })();
    },
    [handler]
  );
  return {
    loading,
    value,
    error,
    perform,
  };
};
