import { useMemo } from 'react';
import { useDocuments } from './useDocuments';

export const useDocumentsReady = (workId?: string) => {
  const { value, loading } = useDocuments();
  const neededDocs = useMemo(() => {
    if (!value) return value;
    return value.filter((item) => {
      const condition_sets = [...(item.condition_sets_mandatory || [])];
      return (
        condition_sets.includes('all') ||
        condition_sets.includes('ip') ||
        condition_sets.includes('org') ||
        (workId ? condition_sets.includes(workId) : false)
      );
    });
  }, [workId, value]);
  const result = useMemo(
    () => ({
      loading,
      ready: loading
        ? false
        : neededDocs
        ? neededDocs.filter(
            (item) => item.status === 'applied' || item.status === 'approved'
          ).length === neededDocs.length
        : false,
    }),
    [loading, neededDocs]
  );
  return result;
};
