import type { ReactNode } from 'react';

import { BreadCrumbs as Control } from 'shared/ui';

import { Approve } from 'features/Document';

interface BreadCrumbsProps {
  title?: ReactNode;
}

export const BreadCrumbs = ({ title }: BreadCrumbsProps) => {
  return (
    <Control content={title}>
      <Approve />
    </Control>
  );
};
