export const CONTACTS = {
  HOTLINE: {
    PHONE: '+74956440200',
    FORMATTED: '+7 (495) 644 02 00',
  },
  TENDER_OFFICE: {
    PHONE: '+74956440200',
    FORMATTED: '+7 (495) 644 02 00',
    EMAIL: 'tenders@glavstroy.ru',
  },
};
