const pad2 = (value: number | string) => {
  if (typeof value !== 'string') value = value.toString();
  return value.length === 1 ? '0' + value : value;
};

export const nowText = () => {
  const now = new Date();
  return [
    [now.getFullYear(), pad2(now.getMonth() + 1), pad2(now.getDate())].join(
      '-'
    ),
    [pad2(now.getHours()), pad2(now.getMinutes()), pad2(now.getSeconds())].join(
      ':'
    ),
  ].join('T');
};
