import clsx from 'clsx';
import {
  useDocumentUpload,
  useDocumentDownload,
} from 'entities/Document/hooks';
import { Button, DateTime, Typography } from 'shared/ui';
import { Spinner } from 'shared/ui/components/Spinner';

import { UploadAction } from '../UploadAction';

import styles from './Item.module.css';

interface ItemProps {
  className?: string;
  id: string;
  name: string;
  approvalEnds?: string;
  rejectedReason?: string;
  fileId?: string;
  docId?: string;
  fileName?: string;
  required?: boolean;
  format: string;
  size: number;
  status: string;
  onRefresh?: () => void;
}

const STATUSES = {
  applied: 'Документ не проверен',
  approved: 'Документ одобрен',
  rejected: (
    <Typography.Text color="error">Документ не прошел проверку</Typography.Text>
  ),
};

export const Item = ({
  className,
  id,
  approvalEnds,
  rejectedReason,
  required,
  name,
  docId,
  fileId,
  fileName,
  size,
  status,
  format,
  onRefresh,
}: ItemProps) => {
  const { loading, onUpload } = useDocumentUpload(id, onRefresh);
  const { loading: downloading, onDownload } = useDocumentDownload(fileId);
  const { loading: downloadingDocument, onDownload: onDownloadDocument } =
    useDocumentDownload(docId, true);
  console.log('docId', docId);
  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.names}>
        <div className={styles.name}>
          {name}{' '}
          {required ? (
            <Typography.Label color="error">*</Typography.Label>
          ) : null}
        </div>
        <div className={styles.statusText}>
          {status ? STATUSES[status] : null}
          {approvalEnds ? (
            <Typography.Text>
              Действует до <DateTime value={approvalEnds} />{' '}
            </Typography.Text>
          ) : null}
          {rejectedReason ? (
            <div>
              <Typography.Text>Причина:</Typography.Text>{' '}
              <Typography.Text>{rejectedReason}</Typography.Text>
            </div>
          ) : null}
          {docId ? (
            <div>
              <Button
                loading={downloadingDocument}
                onClick={onDownloadDocument}
              >
                Скачать шаблон
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      {fileName ? (
        <div className={styles.fileName} onClick={onDownload}>
          {fileName}
          {downloading ? <Spinner active /> : null}
        </div>
      ) : null}
      <span className={styles.upload}>
        <UploadAction loading={loading} format={format} onUpload={onUpload} />
      </span>
    </div>
  );
};
