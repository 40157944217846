import { useCallback, useState } from 'react';
import { api } from 'shared/api';
import { saveAs } from 'shared/lib/saveAs';

export const useDownload = () => {
  const [loading, setLoading] = useState(false);
  const onDownload = useCallback((id?: string) => {
    if (!id) return;
    setLoading(true);
    (async () => {
      try {
        const data = await api.downloadFileAnonymous({ id });
        await saveAs(data.data, data.name);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    })();
  }, []);
  return {
    loading,
    onDownload,
  };
};
