import { type PropsWithChildren } from 'react';

import { Query as Store } from 'shared/data';
import { useMessagesQuery } from '../../hooks';
import { Context } from '../../model';

export const Query = ({ children }: PropsWithChildren<{}>) => {
  /*
  const [pendingMessages, setPendingMessages] = useState<Message[]>();
  const handleMerge = useCallback(
    (prev: ChatSlice, state: ChatSlice) => {
      let result = prev === state ? prev : state;
      if (pendingMessages) {
        const newIds = pendingMessages
          .map((item) => item.id)
          .filter((id) => !result.byId[id]);
        if (newIds.length) {
          
        }
      }
      return result;
    },
    [pendingMessages]
  );*/
  return (
    <Store Context={Context} useQuery={useMessagesQuery} params={undefined}>
      {children}
    </Store>
  );
};
