import { useCallback } from 'react';
import { api } from 'shared/api';
import { useQuery } from 'shared/hooks';
import { useContextBus } from 'shared/hooks/useContextBus';
import { Context } from '../model/context';
import { Message, PostMessageRequest } from 'shared/types';
import { PostedMessage } from './useMessagesQuery';

let id = 0;

const generateId = () => {
  const newId = ++id;
  return `created-message-${newId}`;
};

export const useSubmit = () => {
  const { post } = useContextBus<Partial<Message> | PostedMessage>(Context);
  const { loading, value, perform } = useQuery(api.postMessage);
  const handlePerform = useCallback(
    (value: PostMessageRequest) => {
      const message = {
        created_at: value.date,
        text: value.text,
        type: 'in',
        id: generateId(),
      } as Partial<Message>;
      post('message.posting', message);
      (async () => {
        const id = await perform(value);
        id && post('message.posted', { message, id });
      })();
    },
    [perform, post]
  );
  return {
    loading,
    value,
    perform: handlePerform,
  };
};
