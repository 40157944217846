import { type InputHTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './Input.module.css';
import { TextColor } from '../Typography/types';

interface NativeInputProps {
  color?: TextColor;
}

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'color'> &
  NativeInputProps;

export const Input = ({ className, color, ...props }: InputProps) => {
  return (
    <input
      className={clsx(
        styles.container,
        color ? styles[`color_${color}`] : null,
        className
      )}
      {...props}
    />
  );
};
