import { useEffect, useMemo } from 'react';
import { api } from 'shared/api';
import { useQuery as useSharedQuery } from 'shared/hooks';
import { type TenderScheduleSlice, EMPTY_SLICE } from '../model';

export const useQuery = () => {
  const { loading, value, perform } = useSharedQuery(api.getTenderSchedules);
  useEffect(() => {
    perform(undefined as unknown);
  }, [perform]);
  return useMemo(
    () => ({
      value: {
        loading,
        value: value || EMPTY_SLICE.value,
      } as TenderScheduleSlice,
      refresh: perform,
    }),
    [loading, value, perform]
  );
};
