import { Auctions as Control } from 'widgets/Auction';
import { BreadCrumbs } from 'shared/ui';
import { List as TenderSchedule } from 'widgets/TenderSchedule';

export const Auctions = () => {
  return (
    <>
      <BreadCrumbs content="Тендеры" />
      <Control />
      <TenderSchedule />
    </>
  );
};
