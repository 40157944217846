import { useCallback } from 'react';
import { Editor as Control } from 'entities/Work';
import { useEditor } from '../../hooks';
import { useRefresh } from 'entities/Entity';
import { useNavigate } from 'react-router-dom';

export const Editor = () => {
  const navigate = useNavigate();
  const onRefresh = useRefresh();
  const handleRefresh = useCallback(() => {
    onRefresh();
    navigate(-1);
  }, [navigate, onRefresh]);
  const { loading, works, onToggle, onSubmit, posting, error } = useEditor({
    onRefresh: handleRefresh,
  });
  return (
    <Control
      error={error}
      posting={posting}
      loading={loading}
      value={works}
      onSubmit={onSubmit}
      onToggle={onToggle}
    />
  );
};
