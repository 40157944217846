import { Profile as View } from 'widgets/User';

import { BreadCrumbs } from 'shared/ui';

export const Profile = () => {
  return (
    <>
      <BreadCrumbs content="Профиль" />
      <View />
    </>
  );
};
