import { useCallback } from 'react';
import { Register as Form, useRegister } from 'entities/Session';
import type { RegisterData, RegisterRequest } from 'shared/types';

export const Register = () => {
  const { loading, perform, value, error } = useRegister();
  const handleSubmit = useCallback(
    (data: RegisterData) => {
      const request = {
        ...data,
        validation_addr: window.location.origin + '/confirmation',
      } as RegisterRequest;
      perform(request);
    },
    [perform]
  );
  return (
    <Form
      loading={loading}
      onSubmit={handleSubmit}
      error={error}
      sent={value}
    />
  );
};
