import { Restore as Form } from 'entities/Session';
import { useRestore } from '../../hooks/useRestore';

interface RestoreProps {
  successUrl: string;
}

export const Restore = ({ successUrl }: RestoreProps) => {
  const { loading, sent, error, onSubmit } = useRestore(successUrl);
  return (
    <Form loading={loading} sent={sent} error={error} onSubmit={onSubmit} />
  );
};
