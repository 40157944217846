import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Editor as Form, useProfile, useRefresh } from 'entities/User';
import { useEditor } from '../../hooks';

export const Editor = () => {
  const onRefresh = useRefresh();
  const navigate = useNavigate();
  const handleRefresh = useCallback(() => {
    onRefresh();
    navigate(-1);
  }, [navigate, onRefresh]);
  const { loading, value: user } = useProfile();
  const { posting, value, error, onChange, onSubmit } = useEditor(
    user,
    handleRefresh
  );
  return (
    <Form
      error={error}
      loading={loading}
      posting={posting}
      value={value}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  );
};
