import { createContext } from 'react';

import type { SessionData, UpdateSession } from './types';

export const EMPTY_SESSION: SessionData = {
  loading: true,
  loaded: false,
  present: false,
  token: undefined,
};

export const EMPTY_UPDATE = (() => {}) as unknown as UpdateSession;

export interface SessionContextData {
  session: SessionData;
  setSession: UpdateSession;
}

export const EMPTY_CONTEXT: SessionContextData = {
  session: EMPTY_SESSION,
  setSession: EMPTY_UPDATE,
};

export const SessionContext = createContext(EMPTY_CONTEXT);
