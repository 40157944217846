import clsx from 'clsx';

import styles from './Spinner.module.css';

interface SpinnerProps {
  className?: string;
  active?: boolean;
}

export const Spinner = ({ className, active }: SpinnerProps) => {
  return (
    <div
      className={clsx(
        styles.spinner,
        active ? styles.is_active : null,
        className
      )}
    >
      <div className={styles['spinner-circle-1']} />
      <div className={styles['spinner-circle-2']} />
      <div className={styles['spinner-circle-3']} />
      <div className={styles['spinner-circle-4']} />
    </div>
  );
};
