import { PropsWithChildren } from 'react';

import { Header } from '../components/Header';

import styles from './Public.module.css';

export const Public = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Header />
      <div className={styles.container}>{children}</div>
    </>
  );
};
