import { CONTACTS } from '../../../../constants';
import styles from './Hotline.module.css';

export const Hotline = () => {
  return (
    <a className={styles.container} href={`tel:${CONTACTS.HOTLINE.PHONE}`}>
      Горячая линия {CONTACTS.HOTLINE.FORMATTED}
    </a>
  );
};
