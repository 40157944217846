import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { api } from 'shared/api';
import { useQuery } from 'shared/hooks';

export const useConfirmation = () => {
  const { loading, error, value, perform } = useQuery(api.confirm);
  const { validation_code } = useParams();
  console.log('params', validation_code);
  useEffect(() => {
    if (validation_code) perform({ validation_code });
  }, [validation_code, perform]);
  return {
    loading,
    error: !validation_code ? 'Неверный код подтверждения регистрации' : error,
    value,
  };
};
