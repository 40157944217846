type InnError = 'empty' | 'invalid' | 'wrong-length' | 'wrong-crc';

export const parseInn = (value: string) => [
  0,
  ...value.split('').map((item) => +item),
];

export const checkIndividualCrc = (value: string): InnError | undefined => {
  const n = parseInn(value);
  if (
    ((7 * n[1] +
      2 * n[2] +
      4 * n[3] +
      10 * n[4] +
      3 * n[5] +
      5 * n[6] +
      9 * n[7] +
      4 * n[8] +
      6 * n[9] +
      8 * n[10]) %
      11) %
      10 !==
      n[11] ||
    ((3 * n[1] +
      7 * n[2] +
      2 * n[3] +
      4 * n[4] +
      10 * n[5] +
      3 * n[6] +
      5 * n[7] +
      9 * n[8] +
      4 * n[9] +
      6 * n[10] +
      8 * n[11]) %
      11) %
      10 !==
      n[12]
  )
    return 'wrong-crc';
};
export const checkEntityCrc = (value: string): InnError | undefined => {
  const n = parseInn(value);
  if (
    ((2 * n[1] +
      4 * n[2] +
      10 * n[3] +
      3 * n[4] +
      5 * n[5] +
      9 * n[6] +
      4 * n[7] +
      6 * n[8] +
      8 * n[9]) %
      11) %
      10 !==
    n[10]
  )
    return 'wrong-crc';
};

export const checkInnError = (value: string): InnError | undefined => {
  value = value.trim();
  if (!value) return 'empty';
  const re = /\D/gim;
  if (re.test(value)) return 'invalid';
  if (value.length === 10) return checkEntityCrc(value);
  if (value.length === 12) return checkIndividualCrc(value);
  return 'wrong-length';
};

export const getInnErrorText = (value?: InnError) => {
  if (!value) return;
  if (value === 'empty') return 'Пустое';
  if (value === 'wrong-crc') return 'Ошибка в номере';
  if (value === 'wrong-length') return 'Неправильная длина номера';
  if (value === 'invalid') return 'Неправильное значение';
};
