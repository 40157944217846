import { BreadCrumbs } from 'shared/ui';
import { Editor } from 'widgets/User';

export const ProfileEditor = () => {
  return (
    <>
      <BreadCrumbs content="Профиль" />
      <Editor />
    </>
  );
};
