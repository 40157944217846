import { type Context, useCallback } from 'react';

type RegistryProc<P> = (event: string, payload?: P) => void;

const registry = new Map<Context<any>, RegistryProc<any>[]>();

const _post = <P = void>(context: Context<any>, event: string, payload?: P) => {
  const procs = registry.get(context);
  if (procs) {
    procs.forEach((proc) => proc(event, payload));
  }
};

const _subscribe = <P = void>(
  context: Context<any>,
  handler: RegistryProc<P>
) => {
  const items = registry.get(context) || [];
  items.push(handler);
  registry.set(context, items);
  return () => {
    const items = registry.get(context);
    if (items) {
      const i = items.indexOf(handler);
      if (i >= 0) items.splice(i, 0);
      if (items.length === 0) registry.delete(context);
    }
  };
};

export const useContextBus = <P = void>(context: Context<any>) => {
  const post = useCallback(
    (event: string, payload: P) => {
      return _post(context, event, payload);
    },
    [context]
  );
  const subscribe = useCallback(
    (handler: RegistryProc<any>) => {
      return _subscribe(context, handler);
    },
    [context]
  );
  return {
    post,
    subscribe,
  };
};
