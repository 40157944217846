import { useContext, useCallback } from 'react';
import { useContextBus } from 'shared/hooks/useContextBus';
import { Context, EntitySlice } from '../model';

export { shallow } from 'shared/data';

export const useStore = <T>(
  selector: (state: EntitySlice) => T,
  equalityFn?: (a1: T, a2: T) => boolean
) => {
  const useStore = useContext(Context);
  return useStore(selector);
};

export const useRefresh = () => {
  const post = useContextBus(Context).post;
  return useCallback(() => {
    return post('refresh');
  }, [post]);
};
