import { useEffect } from 'react';
import { api } from 'shared/api';
import { useQuery } from 'shared/hooks';

const param: unknown = undefined;

export const useAuctions = () => {
  const result = useQuery(api.getAuctions);
  const { perform } = result;
  useEffect(() => {
    perform(param);
  }, [perform]);
  return result;
};
