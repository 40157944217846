import { Typography } from 'shared/ui';
import { TenderOffice } from 'widgets/Contact';

export const Contacts = () => {
  return (
    <>
      <Typography.Title size="small">Контакты</Typography.Title>
      <TenderOffice />
    </>
  );
};
