import { useMemo } from 'react';

interface DateTimeProps {
  value?: string;
}

const format = (value: string) =>
  value.split('T')[0].split('-').reverse().join('.');

export const DateTime = ({ value }: DateTimeProps) => {
  const result = useMemo(() => {
    if (!value) return null;
    return <>{format(value)}</>;
  }, [value]);
  return result;
};
