import { useCallback, type FormEventHandler } from 'react';

import { Button, Content, Field, Typography } from 'shared/ui';
import { RestoreRequest } from 'shared/types';

const RESTORE_PASS_ADDR = window.location.origin + '/restore/confirm';

interface RestoreProps {
  loading?: boolean;
  error?: string;
  sent?: boolean;
  onSubmit: (data: RestoreRequest) => void;
}

interface Elements {
  login: HTMLInputElement;
  email: HTMLInputElement;
}

export const Restore = ({ loading, error, sent, onSubmit }: RestoreProps) => {
  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const { login, email } = e.currentTarget.elements as unknown as Elements;
      const data: RestoreRequest = {
        login: login.value,
        email: email.value,
        restore_pass_addr: RESTORE_PASS_ADDR,
      };
      onSubmit(data);
    },
    [onSubmit]
  );
  return (
    <form onSubmit={handleSubmit}>
      <Content inplace>
        <Field
          name="login"
          caption="Логин"
          autoFocus
          color={error ? 'error' : undefined}
        />
        <Field
          name="email"
          caption="Электронная почта"
          color={error ? 'error' : undefined}
        />
        <Button color="primary" loading={loading}>
          Сбросить пароль
        </Button>
        {error ? (
          <Typography.Text pre color="error">
            {error}
          </Typography.Text>
        ) : null}
      </Content>
    </form>
  );
};
