import { Button, List } from 'shared/ui';
import { Logout } from 'features/Session';

import styles from './Navigation.module.css';

export const Navigation = () => {
  return (
    <List className={styles.container}>
      <Button className={styles.item} href="/profile/">
        Информация о пользователе
      </Button>
      <Button className={styles.item} href="/entity/">
        Информация об организации
      </Button>
      <Button className={styles.item} href="/documents/">
        Подтверждающие документы
      </Button>
      <Button className={styles.item} href="/auctions/">
        Доступные аукционы
      </Button>
      <Logout className={styles.item} block />
    </List>
  );
};
