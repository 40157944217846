import { Button } from 'shared/ui';

import { useLogout } from '../../hooks';

interface LoginProps {
  className?: string;
  block?: boolean;
}

export const Logout = ({ className, block }: LoginProps) => {
  const { loading, handleLogout } = useLogout();
  return (
    <Button
      className={className}
      type="button"
      color="transparent"
      block={block}
      loading={loading}
      onClick={handleLogout}
    >
      Выход
    </Button>
  );
};
