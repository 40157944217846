import type { Message } from 'shared/types';

export interface ChatSlice {
  loading: boolean;
  ids: string[];
  byId: Record<string, Message>;
  lastDate: string;
}

export const EMPTY_SLICE: ChatSlice = {
  loading: true,
  ids: [],
  byId: {},
  lastDate: '',
};

export const applyPosting = (state: ChatSlice, payload: Partial<Message>) => {
  if (!payload.id) return state;
  let result = Object.assign({}, state);
  const i = result.ids.indexOf(payload.id);
  if (i === -1) {
    result.ids = result.ids.slice(0);
    result.ids.push(payload.id);
  }
  result.byId = Object.assign({}, result.byId);
  result.byId[payload.id] = payload as Message;
  return result;
};

export const applyPosted = (
  state: ChatSlice,
  message: Partial<Message>,
  id: string
) => {
  if (!message.id) return state;
  let result = Object.assign({}, state);
  result.ids = result.ids.slice(0);
  result.byId = Object.assign({}, result.byId);
  //
  const i = result.ids.indexOf(message.id);
  if (i >= 0) result.ids[i] = id;
  else {
    const i = result.ids.indexOf(id);
    if (i === -1) result.ids.push(id);
  }
  //
  result.byId[id] = Object.assign({}, message as Message, { id });
  delete result.byId[message.id];
  return result;
};

export const applyUpdates = (state: ChatSlice, payload: Message[]) => {
  let result = state;
  for (let k = 0; k < payload.length; k++) {
    const item = payload[k];
    if (item.created_at > result.lastDate || !result.lastDate) {
      if (result === state) result = Object.assign({}, state);
      result.lastDate = item.created_at;
    }
    if (result.byId[item.id]) {
      if (result === state) result = Object.assign({}, state);
      if (result.byId === state.byId)
        result.byId = Object.assign({}, state.byId);
      result.byId[item.id] = Object.assign({}, result.byId[item.id], item);
    } else {
      if (result === state) result = Object.assign({}, state);
      if (result.ids === state.ids) result.ids = state.ids.slice(0);
      if (result.byId === state.byId)
        result.byId = Object.assign({}, state.byId);
      result.ids.push(item.id);
      result.byId[item.id] = item;
    }
  }
  return result;
};
