import { ReactComponent as Pending } from 'shared/icons/pending.svg';
import { ReactComponent as Posted } from 'shared/icons/done.svg';
import { ReactComponent as Read } from 'shared/icons/done-all.svg';

import styles from './DeliveryStatus.module.css';

interface DeliveryStatusProps {
  posted: boolean;
  read: boolean;
}

export const DeliveryStatus = ({ posted, read }: DeliveryStatusProps) => {
  const icon = read ? <Read /> : posted ? <Posted /> : <Pending />;
  return <span className={styles.container}>{icon}</span>;
};
