import { createElement, useContext, type FC } from 'react';

import { SessionContext } from 'entities/Session/model';

interface PublicProps {
  Component: FC;
}

export const Public = ({ Component }: PublicProps) => {
  const { session } = useContext(SessionContext);
  return session.loaded && session.present ? null : createElement(Component);
};
