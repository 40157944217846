import clsx from 'clsx';

import { ReactComponent as SendIcon } from 'shared/icons/send.svg';

import styles from './SendButton.module.css';

interface SendButtonProps {
  className?: string;
  loading?: boolean;
  disabled?: boolean;
}

export const SendButton = ({
  className,
  loading,
  disabled,
}: SendButtonProps) => {
  return (
    <button
      className={clsx(
        styles.container,
        loading && styles['is_loading'],
        className
      )}
      disabled={disabled}
      type="submit"
    >
      <SendIcon />
    </button>
  );
};
