import { RestoreConfirm as Form } from 'entities/Session';
import { useRestoreConfirm } from 'features/Session/hooks/useRestoreConfirm';

interface RestoreConfirmProps {
  successUrl: string;
}

export const RestoreConfirm = ({ successUrl }: RestoreConfirmProps) => {
  const { loading, sent, error, onSubmit } = useRestoreConfirm(successUrl);
  return (
    <Form loading={loading} sent={sent} error={error} onSubmit={onSubmit} />
  );
};
