import { useCallback, type FormEventHandler } from 'react';
import { RestoreConfirmRequest } from 'shared/types';
import { Button, Content, Field, Typography } from 'shared/ui';

interface RestoreConfirmProps {
  loading?: boolean;
  error?: string;
  sent?: boolean;
  onSubmit: (data: RestoreConfirmRequest) => void;
}

interface Elements {
  restore_code: HTMLInputElement;
  hash: HTMLInputElement;
}

export const RestoreConfirm = ({
  loading,
  error,
  sent,
  onSubmit,
}: RestoreConfirmProps) => {
  const handleSubmit = useCallback<FormEventHandler<HTMLFormElement>>(
    (e) => {
      e.preventDefault();
      const { restore_code, hash } = e.currentTarget
        .elements as unknown as Elements;
      const data: RestoreConfirmRequest = {
        restore_code: restore_code.value,
        hash: hash.value,
      };
      onSubmit(data);
    },
    [onSubmit]
  );
  return (
    <form onSubmit={handleSubmit}>
      <Content inplace>
        <Field autoFocus name="restore_code" caption="Код для восстановления" />
        <Field name="hash" caption="Новый пароль" type="password" />
        <Button color="primary" loading={loading}>
          Сохранить
        </Button>
        {error ? (
          <Typography.Text pre color="error">
            {error}
          </Typography.Text>
        ) : null}
      </Content>
    </form>
  );
};
