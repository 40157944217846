import clsx from 'clsx';

import styles from './Checkbox.module.css';

interface CheckboxProps {
  className?: string;
  checked?: boolean;
  onClick: () => void;
}

export const Checkbox = ({ className, checked, onClick }: CheckboxProps) => {
  return (
    <span
      className={clsx(
        styles.container,
        checked ? styles.checked : null,
        className
      )}
      onClick={onClick}
    ></span>
  );
};
