import { useContext, useMemo } from 'react';
import { Context, ScheduleIndex, TenderScheduleSlice } from '../model';

export { shallow } from 'shared/data';

export const useStore = <T>(
  selector: (state: TenderScheduleSlice) => T,
  equalityFn?: (a1: T, a2: T) => boolean
) => {
  const useStore = useContext(Context);
  return useStore(selector);
};

export const useItems = () => {
  const store = useStore((state) => state.value);
  return store;
};

export const MOCK = {
  constrGroups: {
    '6a1ac29c-4e27-11ed-a2f3-00155d862a22': {
      type: 'constrGroup',
      caption: 'Социалка Центр-2',
      id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
      items: [
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group:
            '14.2. Выполнение полного комплекса работ по устройству «вентилируемых» фасадов',
          tender: 'Отделка фасада',
          start_date: '01.03.2024 г.',
          end_date: '25.03.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group:
            '13.1. Черновая и чистовая отделка стен и потолков, квартир, МОП, спец.помещений, тех.помещений. Устройство полов, гидроизоляция. Устройство ГКЛ пер...',
          tender: 'Черновая и чистовая отделка',
          start_date: '01.05.2024 г.',
          end_date: '25.05.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group:
            '15.1. Изготовление и монтаж металлоконструкций (ограждения ЛМ, пандусов, балконов и лоджий, металлические изделия)',
          tender: 'Изготовление и монтаж металлоконструкций',
          start_date: '18.03.2024 г.',
          end_date: '11.04.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group: '19.2. Поставка и монтаж мебели и инвентаря',
          tender: 'Поставка и монтаж дверей',
          start_date: '01.03.2024 г.',
          end_date: '25.03.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group:
            '9.1. Устройство внутренних систем отопления, вентиляции, кондиционирования, водоснабжения и водоотведения',
          tender: 'Внутренние инженерные системы',
          start_date: '18.03.2024 г.',
          end_date: '11.04.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group: '9.3. Устройство внутренней системы электроснабжения',
          tender: 'Поставка НКУ',
          start_date: '01.04.2024 г.',
          end_date: '25.04.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group: '9.4. Устройство внутренних слаботочных систем',
          tender: 'Монтаж системы "Безопасный регион"',
          start_date: '01.09.2024 г.',
          end_date: '01.10.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group:
            '16.1. Поставка, монтаж и ПНР лифтового оборудования, в т.ч. обрамление лифтовых порталов и порогов',
          tender: 'Поставка и монтаж лифтового оборудования',
          start_date: '01.06.2024 г.',
          end_date: '01.07.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group: '17.3. Устройство наружных сетей электроснабжения',
          tender: 'Наружные сети электроснабжения и электроосвещения',
          start_date: '20.04.2024 г.',
          end_date: '20.05.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group: '18.3. Озеленение и ландшафтный дизайн',
          tender: 'Благоустройство и озеленение',
          start_date: '15.01.2025 г.',
          end_date: '14.02.2025 г.',
        },
      ],
    },
    '233a2302-d203-11ed-a2f9-00155d862a22': {
      type: 'constrGroup',
      caption: '4-й квартал',
      id: '233a2302-d203-11ed-a2f9-00155d862a22',
      items: [
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group: '21.3. Гидроизоляция',
          tender: 'Гидроизоляция фундамента, устройство прижимной стенки',
          start_date: '18.03.2024 г.',
          end_date: '01.04.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group: '8.2. Кладка внутренних перегородок и наружных стен',
          tender: 'Кладочные работы (включая кирпичную кладку)',
          start_date: '18.03.2024 г.',
          end_date: '27.04.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '10.1. Выполнение полного комплекса работ по устройству мягкой / эксплуатируемой кровли, молниезащиты, ограждений кровли и крылец, устройству покрыт...',
          tender: 'Устройство кровли',
          start_date: '01.05.2024 г.',
          end_date: '31.05.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '14.1. Выполнение полного комплекса работ по устройству «мокрых» фасадов',
          tender: 'Отделка фасада ("мокрый" фасад)',
          start_date: '01.07.2024 г.',
          end_date: '31.07.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '13.1. Черновая и чистовая отделка стен и потолков, квартир, МОП, спец.помещений, тех.помещений. Устройство полов, гидроизоляция. Устройство ГКЛ пер...',
          tender: 'Черновая и чистовая отделка МОП и квартир',
          start_date: '01.09.2024 г.',
          end_date: '01.10.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '15.1. Изготовление и монтаж металлоконструкций (ограждения ЛМ, пандусов, балконов и лоджий, металлические изделия)',
          tender: 'Изготовление и монтаж металлоконструкций',
          start_date: '01.06.2024 г.',
          end_date: '01.07.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '11.1. Оконные и балконные блоки из ПВХ профиля, отливы, приточные клапана, гребенки, замки безопасности',
          tender: 'Поставка и монтаж окон и дверей из ПВХ профиля',
          start_date: '01.05.2024 г.',
          end_date: '31.05.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '11.1. Оконные и балконные блоки из ПВХ профиля, отливы, приточные клапана, гребенки, замки безопасности',
          tender: 'Поставка и монтаж окон и витражей из алюминиевого профиля',
          start_date: '01.06.2024 г.',
          end_date: '01.07.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group: '19.2. Поставка и монтаж мебели и инвентаря',
          tender: 'Поставка и монтаж металлических дверей',
          start_date: '01.06.2024 г.',
          end_date: '01.07.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '9.1. Устройство внутренних систем отопления, вентиляции, кондиционирования, водоснабжения и водоотведения',
          tender: 'Внутренние инженерные системы',
          start_date: '01.06.2024 г.',
          end_date: '01.07.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group: '9.3. Устройство внутренней системы электроснабжения',
          tender: 'Поставка НКУ',
          start_date: '10.06.2024 г.',
          end_date: '10.07.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group: '9.4. Устройство внутренних слаботочных систем',
          tender: 'Монтаж системы "Безопасный регион"',
          start_date: '01.09.2024 г.',
          end_date: '01.10.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '16.1. Поставка, монтаж и ПНР лифтового оборудования, в т.ч. обрамление лифтовых порталов и порогов',
          tender: 'Поставка и монтаж лифтового оборудования',
          start_date: '01.06.2024 г.',
          end_date: '01.07.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group: '17.3. Устройство наружных сетей электроснабжения',
          tender: 'Наружные сети электроснабжения и электроосвещения',
          start_date: '20.04.2024 г.',
          end_date: '20.05.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group: '18.3. Озеленение и ландшафтный дизайн',
          tender: 'Благоустройство и озеленение',
          start_date: '15.01.2025 г.',
          end_date: '14.02.2025 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: 'c7673eba-0e62-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К405 (Ц2)',
          tender_group: '21.3. Гидроизоляция',
          tender: 'Гидроизоляция',
          start_date: '18.03.2024 г.',
          end_date: '01.04.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: 'c7673eba-0e62-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К405 (Ц2)',
          tender_group: '8.2. Кладка внутренних перегородок и наружных стен',
          tender: 'Внутренние перегородки',
          start_date: '2024 г.',
          end_date: '2024 г.',
        },
      ],
    },
    'bd86fcd2-bc5f-11eb-a2e0-00155d862a22': {
      type: 'constrGroup',
      caption: 'Саввино',
      id: 'bd86fcd2-bc5f-11eb-a2e0-00155d862a22',
      items: [
        {
          constr_group_id: 'bd86fcd2-bc5f-11eb-a2e0-00155d862a22',
          constr_group_name: 'Саввино',
          constr_id: 'b6dc93ba-b21d-11ea-a2d6-00155d862a22',
          constr_name: 'Школа на 550 мест',
          tender_group:
            '5.1 Выполнение полного комплекса работ по стабилизации грунтов, в т.ч. Устройство конструкций по технологии "стена в грунте"',
          tender: 'Стабилизация грунтов',
          start_date: '05.02.2024 г.',
          end_date: '29.02.2024 г.',
        },
        {
          constr_group_id: 'bd86fcd2-bc5f-11eb-a2e0-00155d862a22',
          constr_group_name: 'Саввино',
          constr_id: 'b6dc93ba-b21d-11ea-a2d6-00155d862a22',
          constr_name: 'Школа на 550 мест',
          tender_group:
            '4.1 Погружение и извлечение шпунта, и его последующий возврат (обратный выкуп шпунта)',
          tender: 'Погружение шпунта',
          start_date: 'Март 2024 г.',
          end_date: 'Март 2024 г.',
        },
      ],
    },
  },
  constrs: {
    '08ff53d4-c4bf-11ed-a2f9-00155d862a22': {
      type: 'constr',
      caption: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
      id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
      items: [
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group:
            '14.2. Выполнение полного комплекса работ по устройству «вентилируемых» фасадов',
          tender: 'Отделка фасада',
          start_date: '01.03.2024 г.',
          end_date: '25.03.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group:
            '13.1. Черновая и чистовая отделка стен и потолков, квартир, МОП, спец.помещений, тех.помещений. Устройство полов, гидроизоляция. Устройство ГКЛ пер...',
          tender: 'Черновая и чистовая отделка',
          start_date: '01.05.2024 г.',
          end_date: '25.05.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group:
            '15.1. Изготовление и монтаж металлоконструкций (ограждения ЛМ, пандусов, балконов и лоджий, металлические изделия)',
          tender: 'Изготовление и монтаж металлоконструкций',
          start_date: '18.03.2024 г.',
          end_date: '11.04.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group: '19.2. Поставка и монтаж мебели и инвентаря',
          tender: 'Поставка и монтаж дверей',
          start_date: '01.03.2024 г.',
          end_date: '25.03.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group:
            '9.1. Устройство внутренних систем отопления, вентиляции, кондиционирования, водоснабжения и водоотведения',
          tender: 'Внутренние инженерные системы',
          start_date: '18.03.2024 г.',
          end_date: '11.04.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group: '9.3. Устройство внутренней системы электроснабжения',
          tender: 'Поставка НКУ',
          start_date: '01.04.2024 г.',
          end_date: '25.04.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group: '9.4. Устройство внутренних слаботочных систем',
          tender: 'Монтаж системы "Безопасный регион"',
          start_date: '01.09.2024 г.',
          end_date: '01.10.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group:
            '16.1. Поставка, монтаж и ПНР лифтового оборудования, в т.ч. обрамление лифтовых порталов и порогов',
          tender: 'Поставка и монтаж лифтового оборудования',
          start_date: '01.06.2024 г.',
          end_date: '01.07.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group: '17.3. Устройство наружных сетей электроснабжения',
          tender: 'Наружные сети электроснабжения и электроосвещения',
          start_date: '20.04.2024 г.',
          end_date: '20.05.2024 г.',
        },
        {
          constr_group_id: '6a1ac29c-4e27-11ed-a2f3-00155d862a22',
          constr_group_name: 'Социалка Центр-2',
          constr_id: '08ff53d4-c4bf-11ed-a2f9-00155d862a22',
          constr_name: 'Детское дошкольное учреждение на 220 мест (ДС-4)',
          tender_group: '18.3. Озеленение и ландшафтный дизайн',
          tender: 'Благоустройство и озеленение',
          start_date: '15.01.2025 г.',
          end_date: '14.02.2025 г.',
        },
      ],
    },
    '8187309e-0e83-11ed-a2f2-00155d862a22': {
      type: 'constr',
      caption: 'Центр-2_Жилой дом К408 (Ц2)',
      id: '8187309e-0e83-11ed-a2f2-00155d862a22',
      items: [
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group: '21.3. Гидроизоляция',
          tender: 'Гидроизоляция фундамента, устройство прижимной стенки',
          start_date: '18.03.2024 г.',
          end_date: '01.04.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group: '8.2. Кладка внутренних перегородок и наружных стен',
          tender: 'Кладочные работы (включая кирпичную кладку)',
          start_date: '18.03.2024 г.',
          end_date: '27.04.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '10.1. Выполнение полного комплекса работ по устройству мягкой / эксплуатируемой кровли, молниезащиты, ограждений кровли и крылец, устройству покрыт...',
          tender: 'Устройство кровли',
          start_date: '01.05.2024 г.',
          end_date: '31.05.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '14.1. Выполнение полного комплекса работ по устройству «мокрых» фасадов',
          tender: 'Отделка фасада ("мокрый" фасад)',
          start_date: '01.07.2024 г.',
          end_date: '31.07.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '13.1. Черновая и чистовая отделка стен и потолков, квартир, МОП, спец.помещений, тех.помещений. Устройство полов, гидроизоляция. Устройство ГКЛ пер...',
          tender: 'Черновая и чистовая отделка МОП и квартир',
          start_date: '01.09.2024 г.',
          end_date: '01.10.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '15.1. Изготовление и монтаж металлоконструкций (ограждения ЛМ, пандусов, балконов и лоджий, металлические изделия)',
          tender: 'Изготовление и монтаж металлоконструкций',
          start_date: '01.06.2024 г.',
          end_date: '01.07.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '11.1. Оконные и балконные блоки из ПВХ профиля, отливы, приточные клапана, гребенки, замки безопасности',
          tender: 'Поставка и монтаж окон и дверей из ПВХ профиля',
          start_date: '01.05.2024 г.',
          end_date: '31.05.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '11.1. Оконные и балконные блоки из ПВХ профиля, отливы, приточные клапана, гребенки, замки безопасности',
          tender: 'Поставка и монтаж окон и витражей из алюминиевого профиля',
          start_date: '01.06.2024 г.',
          end_date: '01.07.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group: '19.2. Поставка и монтаж мебели и инвентаря',
          tender: 'Поставка и монтаж металлических дверей',
          start_date: '01.06.2024 г.',
          end_date: '01.07.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '9.1. Устройство внутренних систем отопления, вентиляции, кондиционирования, водоснабжения и водоотведения',
          tender: 'Внутренние инженерные системы',
          start_date: '01.06.2024 г.',
          end_date: '01.07.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group: '9.3. Устройство внутренней системы электроснабжения',
          tender: 'Поставка НКУ',
          start_date: '10.06.2024 г.',
          end_date: '10.07.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group: '9.4. Устройство внутренних слаботочных систем',
          tender: 'Монтаж системы "Безопасный регион"',
          start_date: '01.09.2024 г.',
          end_date: '01.10.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group:
            '16.1. Поставка, монтаж и ПНР лифтового оборудования, в т.ч. обрамление лифтовых порталов и порогов',
          tender: 'Поставка и монтаж лифтового оборудования',
          start_date: '01.06.2024 г.',
          end_date: '01.07.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group: '17.3. Устройство наружных сетей электроснабжения',
          tender: 'Наружные сети электроснабжения и электроосвещения',
          start_date: '20.04.2024 г.',
          end_date: '20.05.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: '8187309e-0e83-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К408 (Ц2)',
          tender_group: '18.3. Озеленение и ландшафтный дизайн',
          tender: 'Благоустройство и озеленение',
          start_date: '15.01.2025 г.',
          end_date: '14.02.2025 г.',
        },
      ],
    },
    'b6dc93ba-b21d-11ea-a2d6-00155d862a22': {
      type: 'constr',
      caption: 'Школа на 550 мест',
      id: 'b6dc93ba-b21d-11ea-a2d6-00155d862a22',
      items: [
        {
          constr_group_id: 'bd86fcd2-bc5f-11eb-a2e0-00155d862a22',
          constr_group_name: 'Саввино',
          constr_id: 'b6dc93ba-b21d-11ea-a2d6-00155d862a22',
          constr_name: 'Школа на 550 мест',
          tender_group:
            '5.1 Выполнение полного комплекса работ по стабилизации грунтов, в т.ч. Устройство конструкций по технологии "стена в грунте"',
          tender: 'Стабилизация грунтов',
          start_date: '05.02.2024 г.',
          end_date: '29.02.2024 г.',
        },
        {
          constr_group_id: 'bd86fcd2-bc5f-11eb-a2e0-00155d862a22',
          constr_group_name: 'Саввино',
          constr_id: 'b6dc93ba-b21d-11ea-a2d6-00155d862a22',
          constr_name: 'Школа на 550 мест',
          tender_group:
            '4.1 Погружение и извлечение шпунта, и его последующий возврат (обратный выкуп шпунта)',
          tender: 'Погружение шпунта',
          start_date: 'Март 2024 г.',
          end_date: 'Март 2024 г.',
        },
      ],
    },
    'c7673eba-0e62-11ed-a2f2-00155d862a22': {
      type: 'constr',
      caption: 'Центр-2_Жилой дом К405 (Ц2)',
      id: 'c7673eba-0e62-11ed-a2f2-00155d862a22',
      items: [
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: 'c7673eba-0e62-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К405 (Ц2)',
          tender_group: '21.3. Гидроизоляция',
          tender: 'Гидроизоляция',
          start_date: '18.03.2024 г.',
          end_date: '01.04.2024 г.',
        },
        {
          constr_group_id: '233a2302-d203-11ed-a2f9-00155d862a22',
          constr_group_name: '4-й квартал',
          constr_id: 'c7673eba-0e62-11ed-a2f2-00155d862a22',
          constr_name: 'Центр-2_Жилой дом К405 (Ц2)',
          tender_group: '8.2. Кладка внутренних перегородок и наружных стен',
          tender: 'Внутренние перегородки',
          start_date: '2024 г.',
          end_date: '2024 г.',
        },
      ],
    },
  },
};
export const useIndexes = () => {
  const items = useItems();
  return useMemo(() => {
    const constrGroups = {} as Record<string, ScheduleIndex>;
    const constrs = {} as Record<string, ScheduleIndex>;
    const parents = {} as Record<string, ScheduleIndex>;
    items?.forEach((item) => {
      if (!item.constr_group_parent_id) {
        if (!parents[item.constr_group_id]) {
          parents[item.constr_group_id] = {
            id: item.constr_group_id,
            caption: item.constr_group_name,
            type: 'parent',
            items: [],
          };
        }
      } else {
        if (!parents[item.constr_group_parent_id]) {
          parents[item.constr_group_parent_id] = {
            id: item.constr_group_parent_id,
            caption: item.constr_group_parent_name!,
            type: 'parent',
            items: [],
          };
        }
        parents[item.constr_group_parent_id].items.push(item);
      }
      if (item.constr_group_id) {
        let constrGroup = constrGroups[item.constr_group_id];
        if (!constrGroup) {
          constrGroup = constrGroups[item.constr_group_id] = {
            type: 'constrGroup',
          } as ScheduleIndex;
          constrGroup.caption = item.constr_group_name;
          constrGroup.id = item.constr_group_id;
          constrGroup.items = [];
        }
        constrGroup.items.push(item);
      }
      if (item.constr_id) {
        let constr = constrs[item.constr_id];
        if (!constr) {
          constr = constrs[item.constr_id] = {
            type: 'constr',
          } as ScheduleIndex;
          constr.caption = item.constr_name;
          constr.id = item.constr_id;
          constr.items = [];
        }
        constr.items.push(item);
      }
    });
    return {
      constrGroups,
      constrs,
      parents,
    };
  }, [items]);
};
