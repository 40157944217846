import { useCallback, useState as useReactState, useRef } from 'react';

type Timeout = ReturnType<typeof setTimeout>;
const TIMEOUT = 400;

export const useState = () => {
  const refTimer = useRef<Timeout>();
  const [open, setOpen] = useReactState(false);
  const refState = useRef(open);
  refState.current = open;
  const setOpenTrue = useCallback(() => {
    if (refTimer.current) {
      clearTimeout(refTimer.current);
      refTimer.current = undefined;
    }
    setOpen(true);
  }, [refTimer, setOpen]);
  const setOpenFalse = useCallback(() => {
    if (refTimer.current) {
      clearTimeout(refTimer.current);
      refTimer.current = undefined;
    }
    refTimer.current = setTimeout(() => {
      setOpen(false);
    }, TIMEOUT);
  }, [refTimer, setOpen]);
  const setOpenFalseNow = useCallback(() => {
    if (refTimer.current) {
      clearTimeout(refTimer.current);
      refTimer.current = undefined;
    }
    setOpen(false);
  }, [refTimer, setOpen]);
  return {
    open,
    setOpen: setOpenTrue,
    setClose: setOpenFalse,
    setCloseNow: setOpenFalseNow,
  };
};
