import type { FC } from 'react';
import type { ReferenceDocument } from 'shared/types';
import { List as ListControl } from 'shared/ui';

import { Item } from '../Item';

export interface ListProps {
  className?: string;
  loading?: boolean;
  items?: ReferenceDocument[];
  downloading?: boolean;
  downloadingId?: string;
  onDownload?: (fileId: string) => void;
}

const LOADING_ITEMS = [undefined, undefined, undefined] as (
  | ReferenceDocument
  | undefined
)[];

export const List: FC<ListProps> = ({
  className,
  loading,
  items,
  downloading,
  downloadingId,
  onDownload,
}) => {
  const visibleItems = loading ? LOADING_ITEMS : items;
  return (
    <ListControl className={className}>
      {visibleItems?.map((item, i) => (
        <Item
          loading={loading}
          value={item}
          downloading={
            (downloading &&
              downloadingId &&
              item &&
              item.file_id === downloadingId) ||
            false
          }
          onDownload={onDownload}
          key={i}
        />
      ))}
    </ListControl>
  );
};
