import { ReplySource as Control, useMessage } from 'entities/Chat';

interface ReplySourceProps {
  id: string;
  onClick?: (id: string) => void;
}

export const ReplySource = ({ id, onClick }: ReplySourceProps) => {
  const message = useMessage(id);
  return <Control id={id} text={message.text} onClick={onClick} />;
};
