import { ChangeEventHandler, useCallback } from 'react';
import { Action } from 'shared/ui';
import styles from './UploadAction.module.css';

interface UploadActionProps {
  loading?: boolean;
  format: string;
  onUpload: (file: File) => void;
}

const ACCEPTS = {
  PDF: 'application/pdf',
  XLS: 'application/vnd.ms-excel',
  DOC: 'application/vnd.ms-word',
};

export const UploadAction = ({
  loading,
  format,
  onUpload,
}: UploadActionProps) => {
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.currentTarget.files && e.currentTarget.files[0]) {
        const file = e.currentTarget.files[0];
        onUpload(file);
        e.currentTarget.value = '';
      }
    },
    [onUpload]
  );
  return (
    <div className={styles.container}>
      <Action icon="upload" loading={loading} />
      <input
        className={styles.control}
        type="file"
        accept={ACCEPTS[format]}
        onChange={handleChange}
      />
    </div>
  );
};
