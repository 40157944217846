import {
  useCallback,
  type TextareaHTMLAttributes,
  type KeyboardEventHandler,
} from 'react';
import clsx from 'clsx';

import styles from './TextArea.module.css';

type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  containerClassName?: string;
  autoSubmit?: boolean;
};

export const TextArea = ({
  className,
  containerClassName,
  autoSubmit,
  ...props
}: TextAreaProps) => {
  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLTextAreaElement>>(
    (e) => {
      if (e.key === 'Enter' && !e.altKey && e.ctrlKey) {
        e.currentTarget.form && e.currentTarget.form.requestSubmit();
      }
    },
    []
  );
  return (
    <div className={clsx(styles.container, containerClassName)}>
      <div className={clsx(styles.control, className, styles.measurer)}>
        {props.value}
        {'\n'}
      </div>
      <textarea
        className={clsx(styles.control, className, styles.input)}
        {...props}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};
