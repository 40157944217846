import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetSession } from 'entities/Session';

export const useLogout = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const setSession = useSetSession();
  const handleLogout = useCallback(() => {
    setLoading(true);
    setSession({
      loading: false,
      loaded: true,
      present: false,
      token: '',
    });
    setLoading(false);
    navigate('/');
  }, [navigate, setSession]);
  return {
    loading,
    handleLogout,
  };
};
