import { useEffect, useRef, type PropsWithChildren } from 'react';
import clsx from 'clsx';
import { List } from 'shared/ui';

import styles from './Messages.module.css';

interface MessagesProps {
  className?: string;
  selected?: string;
  empty?: boolean;
  onClick?: () => void;
}

export const Messages = ({
  className,
  selected,
  empty,
  onClick,
  children,
}: PropsWithChildren<MessagesProps>) => {
  const containerRef = useRef<HTMLUListElement>(null);
  useEffect(() => {
    if (selected && containerRef) {
      const child = containerRef.current?.querySelector(
        `[data-id="${selected}"]`
      );
      if (child) child.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selected, containerRef]);
  return (
    <List
      className={clsx(
        styles.container,
        empty ? styles.is_empty : null,
        className
      )}
      orientation="vertical"
      onClick={onClick}
      containerRef={containerRef}
    >
      {empty ? <div className={styles.message}>Сообщений нет</div> : null}
      {children}
    </List>
  );
};
