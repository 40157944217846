import { useMemo, type PropsWithChildren } from 'react';
import clsx from 'clsx';

import { DeliveryStatus } from '../DeliveryStatus';

interface MessageProps {
  id?: string;
  text?: string;
  created_at?: string;
  type?: 'in' | 'out';
  parent?: string;
  read_at?: string;
  read_by?: string;
  loading?: boolean;
  selected?: boolean;
}

import styles from './Message.module.css';

export const Message = ({
  id,
  text,
  created_at,
  type,
  parent,
  read_at,
  read_by,
  selected,
  children,
}: PropsWithChildren<MessageProps>) => {
  const time = useMemo(() => {
    return created_at
      ? created_at.split('T')[1].split(':').slice(0, 2).join(':')
      : '';
  }, [created_at]);
  const posted = useMemo(() => {
    return type === 'in' && !!id && !id.startsWith('created');
  }, [type, id]);
  return (
    <div className={clsx(styles.wrapper, selected && styles['is_selected'])}>
      <div
        className={clsx(styles.container, styles[`direction_${type}`])}
        data-id={id}
      >
        {children}
        <div className={styles.content}>
          <span className={styles.text}>{text}</span>
          <span className={styles.status}>
            <span className={styles.time}>{time}</span>
            {type === 'in' ? (
              <span className={styles.icon}>
                <DeliveryStatus posted={posted} read={!!read_at} />
              </span>
            ) : null}
          </span>
        </div>
      </div>
    </div>
  );
};
