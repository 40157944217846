import { useCallback, useMemo, useRef, useState } from 'react';
import { List as ListControl } from 'shared/ui';
import { Work, WorkNode } from 'shared/types';
import { useTree } from 'shared/hooks';

import { Item } from '../Item';

import styles from './List.module.css';

type ClosedIndex = Record<string, boolean>;

interface ListProps {
  loading?: boolean;
  items?: Work[];
  onToggle: (id: string) => void;
}

interface NodeProps {
  closed: ClosedIndex;
  node: WorkNode;
  level?: number;
  onToggle: (id: string) => void;
}

export const Node = ({ closed, node, onToggle, level = 0 }: NodeProps) => {
  const isFolder = !!(node.children && node.children.length);
  const isClosed = node.value && !closed[node.value.id];
  return (
    <>
      {node.value ? (
        <Item
          className={styles.folder}
          closed={closed[node.value.id]}
          id={node.value.id}
          name={node.value.name}
          folder={isFolder}
          level={level}
          selected={isFolder ? undefined : node.value.selected}
          onToggle={onToggle}
        />
      ) : null}
      {isFolder && !isClosed
        ? node.children?.map((item, i) => (
            <Node
              closed={closed}
              level={level + 1}
              node={item}
              onToggle={onToggle}
              key={i}
            />
          ))
        : null}
    </>
  );
};

export const List = ({ items, onToggle }: ListProps) => {
  const { nodes } = useTree<Work, string, string>({
    items,
    keyField: 'id',
    parentKeyField: 'parent',
  });
  const [closed, setClosed] = useState<ClosedIndex>({});
  const index = useMemo(
    () =>
      items?.reduce((result, item) => {
        result[item.id] = item;
        return result;
      }, {} as Record<string, Work>),
    [items]
  );
  const refIndex = useRef(index);
  refIndex.current = index;
  const handleToggle = useCallback(
    (id: string) => {
      const isFolder = refIndex.current?.[id].is_folder;
      if (!isFolder && onToggle) onToggle(id);
      else {
        setClosed((current) => ({
          ...current,
          [id]: !current[id],
        }));
      }
    },
    [refIndex, onToggle]
  );
  return (
    <ListControl
      className={styles.container}
      itemClassName={styles.item}
      orientation="vertical"
    >
      {nodes.children?.map((node, i) => (
        <Node node={node} closed={closed} onToggle={handleToggle} key={i} />
      ))}
    </ListControl>
  );
};
