import { Profile as Control } from 'entities/User';
import { useStore } from 'entities/User/hooks/useStore';
import { Button, Content } from 'shared/ui';

export const Profile = () => {
  const { loading, value } = useStore((state) => state);
  const filled = typeof value.name;
  const actionCaption = filled ? 'Изменить' : 'Заполнить';
  return (
    <Content inplace>
      <Control
        loading={loading}
        value={value}
        action={
          <Button color="primary" href="edit/">
            {actionCaption}
          </Button>
        }
      />
    </Content>
  );
};
