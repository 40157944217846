import { FormEventHandler, useCallback, useEffect, useState } from 'react';

import { api } from 'shared/api';
import type { User } from 'shared/types';
import { useQuery } from 'shared/hooks';

export const useEditor = (source: User, onRefresh: () => void) => {
  const { loading, error, value: updated, perform } = useQuery(api.updateUser);
  const [value, setValue] = useState<User>(source);
  useEffect(() => {
    setValue(source);
  }, [source]);
  const onSubmit = useCallback<FormEventHandler>(
    (e) => {
      e.preventDefault();
      perform(value);
    },
    [value, perform]
  );
  useEffect(() => {
    if (updated && onRefresh) onRefresh();
  }, [updated, onRefresh]);
  return {
    posting: loading,
    error,
    updated,
    value,
    onChange: setValue,
    onSubmit,
  };
};
