import type { PropsWithChildren } from 'react';
import clsx from 'clsx';

import styles from './Typography.module.css';

export interface TitleProps {
  className?: string;
  level?: number;
  size?: 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall';
  weight?: 'normal';
  block?: boolean;
  onClick?: () => void;
}

export const Title = ({
  className,
  level,
  size,
  weight,
  block,
  onClick,
  children,
}: PropsWithChildren<TitleProps>) => {
  return (
    <div
      className={clsx(
        styles.title,
        size ? styles[`size_${size}`] : null,
        weight ? styles[`weight_${weight}`] : null,
        block ? styles['block'] : null,
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
};
