import { type PropsWithChildren, useEffect } from 'react';
import { useContextBus } from 'shared/hooks/useContextBus';

import { type StoreContext } from '../../model/store';
import { type Merger, Provider } from '../Provider';

interface UseQueryResult<Response, Params> {
  value: Response;
  refresh: (params: Params) => void;
}

type UseQuery<Response extends object, Params> = (
  params: Params
) => UseQueryResult<Response, Params>;

interface QueryProps<Response extends object, Params> {
  Context: StoreContext<Response>;
  useQuery: UseQuery<Response, Params>;
  params: Params;
  merger?: Merger<Response>;
  event?: string;
}

/**
 * Провайдер для запросов
 * @property Context - реакт контекст для стора
 * @property useQuery - хук для запроса по параметрам
 * @property params - параметры запроса
 * @property merger - как обновлять данные в сторе
 */
export const Query = <Response extends object, Params = void>({
  Context,
  useQuery,
  params,
  merger,
  children,
}: PropsWithChildren<QueryProps<Response, Params>>) => {
  const { value, refresh } = useQuery(params);
  const { subscribe } = useContextBus(Context);
  useEffect(() => {
    const unsubscribe = subscribe((event: string) => {
      if (event === 'refresh') refresh(params);
    });
    return () => {
      unsubscribe();
    };
  }, [refresh, subscribe, params]);
  return (
    <Provider<Response> Context={Context} value={value} merger={merger}>
      {children}
    </Provider>
  );
};
