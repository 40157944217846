import { useCallback, useState, type FormEventHandler } from 'react';

import { useSetSession } from 'entities/Session';
import { api } from 'shared/api';

interface UseLoginResult {
  error?: string;
  loading: boolean;
  handleSubmit: FormEventHandler;
}

export const useLogin = (): UseLoginResult => {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const setSession = useSetSession();
  //
  const handleSubmit = useCallback<FormEventHandler>(
    (e) => {
      e.preventDefault();
      const form = e.currentTarget as HTMLFormElement;
      const payload = {
        login: form.elements['login'].value,
        hash: form.elements['password'].value,
      };
      setError(undefined);
      setLoading(true);
      (async () => {
        try {
          const result = await api.login(payload);
          setLoading(false);
          setSession((session) => ({
            loading: false,
            loaded: true,
            present: true,
            token: result,
          }));
        } catch (err) {
          setLoading(false);
          if (err instanceof Error) setError(err.message);
          else setError('Неизвестная ошибка');
        }
      })();
    },
    [setSession]
  );
  return {
    handleSubmit,
    error,
    loading,
  };
};
