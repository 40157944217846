import { type Context, createContext as createReactContext } from 'react';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';

export type Store<Data extends object> = ReturnType<typeof createStore<Data>>;
export type StoreContext<Data extends object> = Context<Store<Data>>;

export const createSlice =
  <T extends object>(initial: T) =>
  (): T =>
    initial;

export const createStore = <T extends object>(initial: T) => {
  const slice = createSlice(initial);
  return create(subscribeWithSelector(slice));
};

export const createContext = <Data extends object>(
  initial: Data
): StoreContext<Data> => createReactContext(createStore(initial));

export { shallow };
