import type { User } from 'shared/types';

export interface UserSlice {
  loading: boolean;
  value: User;
}

export const EMPTY_SLICE: UserSlice = {
  loading: true,
  value: {} as User,
};
