import { Query } from 'entities/Chat';
import { InputBar, Messages } from 'widgets/Chat';

import styles from './Chat.module.css';

export const Chat = () => {
  return (
    <Query>
      <section className={styles.container}>
        <Messages className={styles.messages} />
        <InputBar className={styles.input} />
      </section>
    </Query>
  );
};
