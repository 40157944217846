import { useEffect } from 'react';
import { useContextBus } from 'shared/hooks/useContextBus';
import { Context } from '../model';

import { api } from 'shared/api';
import { useQuery } from 'shared/hooks';

const key: unknown = undefined;

export const useDocuments = () => {
  const { subscribe } = useContextBus(Context);
  const result = useQuery(api.getDocuments);
  const { perform } = result;
  useEffect(() => {
    perform(key);
    const unsubscribe = subscribe(perform);
    return () => {
      unsubscribe();
    };
  }, [perform, subscribe]);
  return result;
};
