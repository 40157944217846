import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { api } from 'shared/api';
import { Message } from 'shared/types';
import { useQuery } from 'shared/hooks';
import { ChatSlice, EMPTY_SLICE } from '../model';
import { applyPosted, applyPosting, applyUpdates } from '../model/slice';
import { useContextBus } from 'shared/hooks/useContextBus';
import { Context } from '../model/context';
import { nowText } from 'shared/lib/date';

export interface PostedMessage {
  message: Partial<Message>;
  id: string;
}

const startDate = nowText();

export const useMessagesQuery = () => {
  const refTimer = useRef<ReturnType<typeof setTimeout>>();
  const { subscribe } = useContextBus(Context);
  const {
    loading,
    value: initial,
    perform: request,
  } = useQuery(api.getMessages);
  const { value: updates, perform: refresh } = useQuery(api.getMessagesUpdates);
  const [value, setValue] = useState<ChatSlice>(EMPTY_SLICE);
  useEffect(() => {
    if (initial) {
      setValue((current) => applyUpdates(current, initial));
    }
  }, [initial]);
  useEffect(() => {
    console.log('applying updates', updates);
    if (updates) setValue((current) => applyUpdates(current, updates));
  }, [updates]);
  useEffect(() => {
    request(undefined);
  }, [request]);
  const handlePosting = useCallback((message: Partial<Message>) => {
    setValue((current) => applyPosting(current, message));
  }, []);
  const handlePosted = useCallback((message: Partial<Message>, id: string) => {
    setValue((current) => applyPosted(current, message, id));
  }, []);
  useEffect(() => {
    const unsubscribe = subscribe((event, payload: unknown) => {
      if (event === 'message.posting') {
        // console.log('[useMessageQuery] event', event, payload);
        setValue((current) =>
          applyPosting(current, payload as Partial<Message>)
        );
      }
      if (event === 'message.posted') {
        // console.log('[useMessageQuery] event', event, payload);
        setValue((current) =>
          applyPosted(
            current,
            (payload as PostedMessage).message,
            (payload as PostedMessage).id
          )
        );
      }
    });
    return () => {
      unsubscribe();
    };
  }, [subscribe, handlePosting, handlePosted]);
  const lastDate = useMemo(() => {
    return value.lastDate || startDate;
  }, [value]);
  console.log('final lastDate', lastDate, value.lastDate);
  useEffect(() => {
    if (refTimer.current) {
      clearInterval(refTimer.current);
      refTimer.current = undefined;
    }
    refTimer.current = setInterval(() => {
      refresh({ start_date: lastDate, end_date: nowText() });
    }, 3000);
    return () => {
      if (refTimer.current) {
        clearInterval(refTimer.current);
        refTimer.current = undefined;
      }
    };
  }, [lastDate, refTimer, refresh]);
  return {
    value: {
      ...value,
      loading,
    },
    refresh: request,
    handlePosting,
    handlePosted,
  };
};
