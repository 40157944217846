import type { FC } from 'react';
import { Query } from 'entities/ReferenceDocument';
import { Items } from './Items';
import { Content, Typography } from 'shared/ui';

interface ListProps {
  className?: string;
}

export const List: FC<ListProps> = ({ className }) => {
  return (
    <Query>
      <Content inplace>
        <Typography.Title level={2} size="small">
          Справочные документы
        </Typography.Title>
        <Typography.Text italic>
          Следующие документы доступны для скачивания:
        </Typography.Text>
        <Items className={className} />
      </Content>
    </Query>
  );
};
