import { useEffect, useState } from 'react';
import { api } from 'shared/api';
import type { Entity } from 'shared/types';

import { useInnError } from './useInnError';

export const useInnLookup = (value?: string) => {
  const innError = useInnError(value || '');
  const [entity, setEntity] = useState<Entity>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  useEffect(() => {
    let cancelled = false;
    (async () => {
      if (error || !value || innError) return;
      setLoading(true);
      setError(undefined);
      try {
        const result = await api.getEntityByInn(value);
        if (cancelled) return;
        setEntity(result);
        setLoading(false);
      } catch (e) {
        console.log(e);
        if (e instanceof Error) setError(e.message);
        setLoading(false);
      }
    })();
    return () => {
      cancelled = true;
    };
  }, [error, value, innError]);
  return {
    loading,
    entity,
    error,
    innError,
  };
};
