import { useCallback, useState, type FC } from 'react';
import { List as ListControl, useDownload } from 'entities/ReferenceDocument';
import { ReferenceDocument } from 'shared/types';

interface ListProps {
  className?: string;
  loading?: boolean;
  items?: ReferenceDocument[];
}

export const List: FC<ListProps> = ({ className, loading, items }) => {
  const { loading: downloading, onDownload } = useDownload();
  const [downloadingId, setDownloadingId] = useState('');
  const handleDownload = useCallback(
    (id: string) => {
      setDownloadingId(id);
      onDownload && onDownload(id);
    },
    [onDownload, setDownloadingId]
  );
  return (
    <ListControl
      className={className}
      loading={loading}
      items={items}
      downloading={downloading}
      downloadingId={downloadingId}
      onDownload={handleDownload}
    />
  );
};
