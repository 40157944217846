import { useCallback, useMemo } from 'react';
import { useDocuments } from 'entities/Document/hooks';
import { List } from 'entities/Document';
import { Approve } from 'features/Document';
import { useWorks, useWorksIndex } from 'entities/Work';

const param: unknown = undefined;

export const Documents = () => {
  const { loading, value, perform } = useDocuments();
  const { loading: worksLoading, value: works } = useWorks();
  const selectedIds = useMemo(
    () => works?.filter((item) => item.selected).map((item) => item.id),
    [works]
  );
  const index = useWorksIndex(works);
  const handleGetCaption = useCallback(
    (id: string) => {
      return index[id]?.name;
    },
    [index]
  );
  const handleGetStatus = useCallback(
    (id) => index[id]?.approval_status,
    [index]
  );
  const handleRefresh = useCallback(() => {
    perform(param);
  }, [perform]);
  return (
    <List
      loading={loading || worksLoading}
      items={value}
      groupIds={selectedIds}
      onRefresh={handleRefresh}
      onGetCaption={handleGetCaption}
      onGetStatus={handleGetStatus}
      action={<Approve />}
    />
  );
};
