import { useState, useEffect } from 'react';
import { Query } from 'entities/TenderSchedule';
import { Items } from './Items';

export const List = () => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, 100);
    return () => {
      clearTimeout(timer);
    };
  }, [setVisible]);
  return (
    <>
      <Query>{visible ? <Items /> : null}</Query>
    </>
  );
};
