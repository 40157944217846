import type { FC } from 'react';
import { useReferenceDocuments } from 'entities/ReferenceDocument';
import { List as ListControl } from 'features/ReferenceDocument';

interface ItemsProps {
  className?: string;
}

export const Items: FC<ItemsProps> = ({ className }) => {
  const { loading, value } = useReferenceDocuments();
  return <ListControl className={className} loading={loading} items={value} />;
};
