import { Typography } from '../Typography';

interface ApprovalStatusProps {
  children?: string;
  before?: string;
}

const STATUSES = {
  not_assigned: 'Не аккредитован',
  requested: 'Ожидает проверки',
  checking: 'На проверке',
  approved: 'Аккредитован',
  rejected: 'Отказ в аккредитации',
};

const COLORS = {
  not_assigned: 'error',
  requested: 'danger',
  checking: 'danger',
  approved: 'success',
  rejected: 'fatal',
};

export const ApprovalStatus = ({ before, children }: ApprovalStatusProps) => {
  return children ? (
    <Typography.Text size="inherit" color={COLORS[children]}>
      {STATUSES[children]}
      {before ? ' до ' + before : ''}
    </Typography.Text>
  ) : null;
};
