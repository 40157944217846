import { Link } from 'react-router-dom';

import { useStore } from 'entities/User/hooks';
import { List, SubMenu } from 'shared/ui';

import styles from './Menu.module.css';

export const Menu = () => {
  const value = useStore((state) => state.value);
  if (!value.login) return null;
  return (
    <List className={styles.container} orientation="horizontal">
      <SubMenu className={styles.link} caption="Моя организация">
        <Link className={styles.subLink} to="/entity/">
          Аккредитация
        </Link>
      </SubMenu>
      <SubMenu className={styles.link} caption="Тендеры">
        <Link className={styles.subLink} to="/auctions/">
          Поиск тендеров
        </Link>
        <Link className={styles.subLink} to="/auctions/">
          Участие
        </Link>
      </SubMenu>
      <Link className={styles.link} to="/chat/">
        Чат
      </Link>
    </List>
  );
};
