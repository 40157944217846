import { Message as Control, useMessage } from 'entities/Chat';
import { ReplySource } from '../ReplySource';

interface MessageProps {
  id: string;
  selected?: boolean;
  onSelect?: (id: string) => void;
}

export const Message = ({ id, selected, onSelect }: MessageProps) => {
  const value = useMessage(id);
  return (
    <Control
      id={id}
      type={value?.type}
      text={value?.text}
      created_at={value?.created_at}
      read_at={value?.read_at}
      read_by={value?.read_by}
      parent={value?.parent}
      selected={selected}
    >
      {value?.parent ? (
        <ReplySource id={value.parent} onClick={onSelect} />
      ) : null}
    </Control>
  );
};
