import { Link } from 'react-router-dom';
import { Content, Input, Typography } from 'shared/ui';
import { useLogin, Login as Action } from 'features/Session';

import styles from './Login.module.css';

export const Login = () => {
  const { loading, error, handleSubmit } = useLogin();
  return (
    <form className={styles.container} onSubmit={handleSubmit}>
      <Content mobile>
        <Typography.Title size="medium" weight="normal">
          Вход в личный кабинет
        </Typography.Title>
        <div />
        <Input placeholder="Логин" name="login" />
        <Input placeholder="Пароль" name="password" type="password" />
        <div />
        <div className={styles.actions}>
          <Action color="primary" loading={loading} block />
          <Link to="/restore/" className={styles.link}>
            Забыли пароль?
          </Link>
        </div>
        {error ? <div>{error}</div> : null}
      </Content>
    </form>
  );
};
