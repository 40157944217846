import { Button } from 'shared/ui';

import { Works as View } from 'entities/Work';
import { useWorks } from 'entities/Work/hooks';
import type { Work } from 'shared/types';
import { useEditorIfEmpty } from 'shared/hooks';

const isFilled = (value?: Work[]) => {
  return true;
};

const href = 'edit/';

export const Works = () => {
  //const { loading, value } = useStore((state) => state);

  const { loading, value } = useWorks();
  useEditorIfEmpty({ loading, value, href, check: isFilled });

  /*const { value: works } = useWorks();
  
  const smr = useMemo(() => {
    if (!works) return null;
    return works
      .filter((item) => item.selected)
      .map((item, i) => <Typography.Text key={i}>{item.name}</Typography.Text>);
  }, [works]);*/

  return (
    <View
      loading={loading}
      value={value}
      action={
        <Button href={href} color="primary">
          Редактировать
        </Button>
      }
    ></View>
  );
};
