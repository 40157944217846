import { useCallback, useEffect, useMemo, useState } from 'react';
import { Messages as Control, useMessages } from 'entities/Chat';
import { Message } from '../Message';

interface MessagesProps {
  className?: string;
}

export const Messages = ({ className }: MessagesProps) => {
  const ids = useMessages();
  const [selected, setSelected] = useState<string>();
  const realIds = useMemo(() => {
    const result = ids.slice(0);
    result.reverse();
    return result;
  }, [ids]);
  useEffect(() => {}, [selected]);
  const handleSelect = useCallback((id: string) => {
    setSelected(id);
  }, []);
  const handleResetSelect = useCallback(() => {
    setSelected(undefined);
  }, []);
  return (
    <Control
      className={className}
      onClick={handleResetSelect}
      selected={selected}
      empty={!ids || !ids.length}
    >
      {realIds
        ? realIds.map((id) => (
            <Message
              id={id}
              key={id}
              onSelect={handleSelect}
              selected={selected === id}
            />
          ))
        : null}
    </Control>
  );
};
