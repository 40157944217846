import type { Auction } from 'shared/types';
import { DateTime } from 'shared/ui';

import styles from './List.module.css';

interface ListProps {
  loading?: boolean;
  items?: Auction[];
}

export const List = ({ loading, items }: ListProps) => {
  return (
    <table className={styles.container}>
      <thead></thead>
      <tbody>
        {items?.map((item, i) => (
          <tr key={i}>
            <td>{item.name}</td>
            <td>
              <DateTime value={item.start_date} />
            </td>
            <td>
              <DateTime value={item.end_date} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
