import { useCallback } from 'react';
import { useApprove, useDocumentsReady } from 'entities/Document/hooks';
import { useStore } from 'entities/User/hooks';
import { Button, Typography } from 'shared/ui';

interface ApproveProps {
  workId?: string;
}

export const Approve = ({ workId }: ApproveProps) => {
  console.log('approve', workId);
  const userStatus = useStore((state) => state.value.approval_status);
  const { loading: loadingDocs, ready } = useDocumentsReady(workId);
  const approved = userStatus === 'approved';
  const waiting =
    (userStatus === 'checking' || userStatus === 'requested') && !workId;
  const canApprove = (!waiting && !loadingDocs && ready) || !!workId;
  const { loading: posting, value, error, perform } = useApprove();
  const hanldeApprove = useCallback(() => {
    perform(workId ? [workId] : undefined);
  }, [perform, workId]);
  //
  console.log(approved, loadingDocs, waiting);
  if (approved) return null;
  if (loadingDocs) return null;
  if (value)
    return <Typography.Text>Документы отправлены на проверку</Typography.Text>;
  if (waiting) return null;
  if (!canApprove)
    return (
      <Typography.Text color="error">
        Для запроса аккредитации необходимо заполнить все формы
      </Typography.Text>
    );
  return (
    <>
      <Button color="primary" loading={posting} onClick={hanldeApprove}>
        Запросить аккредитацию
      </Button>
      {error ? <Typography.Text color="error">{error}</Typography.Text> : null}
    </>
  );
};
