import { type PropsWithChildren, type ReactNode } from 'react';
import { Typography } from '../Typography';

import styles from './BreadCrumbs.module.css';

interface BreadCrumbsProps {
  content?: ReactNode;
}

export const BreadCrumbs = ({
  content,
  children,
}: PropsWithChildren<BreadCrumbsProps>) => {
  return (
    <Typography.Text className={styles.container} color="secondary">
      <span>{content}</span>
      <span>{children}</span>
    </Typography.Text>
  );
};
