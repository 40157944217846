import { type MouseEventHandler, useCallback } from 'react';
import clsx from 'clsx';

import styles from './ReplySource.module.css';

interface ReplySourceProps {
  id: string;
  className?: string;
  name?: string;
  text: string;
  onClick?: (id: string) => void;
}

export const ReplySource = ({
  id,
  className,
  name,
  text,
  onClick,
}: ReplySourceProps) => {
  const handleClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick && onClick(id);
    },
    [onClick, id]
  );
  return (
    <div className={clsx(styles.container, className)} onClick={handleClick}>
      {name ? <span className={styles.name}>{name}</span> : null}
      <span className={styles.text}>{text}</span>
    </div>
  );
};
