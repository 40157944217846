import { UserApprovalStatus } from 'shared/types/user';

const STATUSES = {
  not_assigned: 'Не аккредитован',
  requested: 'Ожидает проверки',
  checking: 'На проверке',
  approved: 'Аккредитован',
  rejected: 'Отказ в аккредитации',
};

export const getStatusString = (value?: UserApprovalStatus) => {
  return value ? STATUSES[value] : null;
};
