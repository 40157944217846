import { useCallback, type FC } from 'react';
import { clsx } from 'clsx';

import { ReferenceDocument } from 'shared/types';
import { ReactComponent as FileSave } from 'shared/icons/file-save.svg';

import style from './Item.module.css';
import { Spinner } from 'shared/ui/components/Spinner';

interface ItemProps {
  loading?: boolean;
  value?: ReferenceDocument;
  downloading?: boolean;
  onDownload?: (fileId: string) => void;
}

export const Item: FC<ItemProps> = ({
  loading,
  value,
  downloading,
  onDownload,
}) => {
  const handleDownload = useCallback(() => {
    value && onDownload && onDownload(value.file_id);
  }, [onDownload, value]);
  return (
    <div
      className={clsx(
        style.container,
        loading ? style.loading : null,
        value ? style.ready : null
      )}
      onClick={handleDownload}
    >
      {value ? <FileSave /> : null}
      {value?.file_name}
      {downloading ? <Spinner active /> : null}
    </div>
  );
};
