import { BrowserRouter } from 'react-router-dom';
import { Root } from './routes/Root';

export const App = () => {
  return (
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  );
};
