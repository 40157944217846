import { type PropsWithChildren, useMemo } from 'react';
import clsx from 'clsx';

import styles from './Skeleton.module.css';

interface SkeletonProps {
  className?: string;
  active?: boolean;
  width?: number;
}

export const Skeleton = ({
  className,
  active,
  width,
  children,
}: PropsWithChildren<SkeletonProps>) => {
  const style = useMemo(
    () =>
      width ? { width: width.toString() + (width ? '%' : '') } : undefined,
    [width]
  );
  if (active)
    return (
      <span
        className={clsx(
          styles.container,
          active ? styles.active : null,
          className
        )}
        style={style}
      >
        {children || 'xx'}
      </span>
    );
  return <>{children}</>;
};
