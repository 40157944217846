import type { Entity } from 'shared/types';

export interface EntitySlice {
  loading: boolean;
  value: Entity;
}

export const EMPTY_SLICE: EntitySlice = {
  loading: true,
  value: {} as Entity,
};
