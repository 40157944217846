import { Button, Content, Typography } from 'shared/ui';
import { Spinner } from 'shared/ui/components/Spinner';
import { useConfirmation } from '../../hooks';

export const Confirmation = () => {
  const { loading, error, value } = useConfirmation();
  return (
    <div>
      <Spinner active={loading} />
      {error ? (
        <Content>
          <Typography.Text color="error">{error}</Typography.Text>
          <Button href="/register/" color="primary">
            Регистрация
          </Button>
        </Content>
      ) : null}
      {value ? (
        <Content>
          <Typography.Text color="primary">
            Регистрация прошла успешно
          </Typography.Text>
          <Button href="/" color="primary">
            Вход
          </Button>
        </Content>
      ) : null}
    </div>
  );
};
