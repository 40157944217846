import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { Content } from '../Content';

import styles from './Frame.module.css';

interface FrameProps {
  className?: string;
}

export const Frame = ({
  className,
  children,
}: PropsWithChildren<FrameProps>) => {
  return (
    <Content className={clsx(styles.container, className)}>{children}</Content>
  );
};
