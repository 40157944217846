import { Session } from 'widgets/Session';

import { Private } from './Private';
import { Public } from './Public';

export const Root = () => {
  return (
    <Session>
      <Session.Public Component={Public} />
      <Session.Private Component={Private} />
    </Session>
  );
};
