import { Button } from 'shared/ui';

import { Entity as View } from 'entities/Entity';
import { useStore } from 'entities/Entity/hooks';
import type { Entity as EntityType } from 'shared/types';
import { useEditorIfEmpty } from 'shared/hooks';

const isFilled = (value?: EntityType) => {
  if (!value) return false;
  return (
    !!value.full_name &&
    !!value.head &&
    !!value.phone &&
    !!value.inn &&
    !!value.kpp &&
    !!value.legal_addr &&
    !!value.ogrn &&
    !!value.type
  );
};

const href = 'edit/';

export const Entity = () => {
  const { loading, value } = useStore((state) => state);
  useEditorIfEmpty({ loading, value, href, check: isFilled });

  /*const { value: works } = useWorks();
  
  const smr = useMemo(() => {
    if (!works) return null;
    return works
      .filter((item) => item.selected)
      .map((item, i) => <Typography.Text key={i}>{item.name}</Typography.Text>);
  }, [works]);*/

  return (
    <View
      loading={loading}
      value={value}
      action={
        <Button href={href} color="primary">
          Редактировать
        </Button>
      }
    ></View>
  );
};
