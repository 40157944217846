import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { api } from 'shared/api';
import { useQuery } from 'shared/hooks';

export const useRestore = (successUrl: string) => {
  const navigate = useNavigate();
  const { loading, error, value, perform } = useQuery(api.restore);
  useEffect(() => {
    if (value && successUrl) navigate(successUrl);
  }, [value, navigate, successUrl]);
  return {
    onSubmit: perform,
    loading,
    sent: value,
    error,
  };
};
