import {
  ConfirmRequest,
  Document,
  DocumentFile,
  UploadDocumentRequest,
  DownloadDocumentRequest,
  Entity,
  RegisterRequest,
  UpdateEntityRequest,
  User,
  Work,
  Auction,
  RestoreRequest,
  RestoreConfirmRequest,
  PostMessageRequest,
  PostMessageResponse,
  Message,
  UpdatesMessagesRequest,
  ReferenceDocument,
  TenderSchedule,
} from '../types';

export interface LoginRequest {
  login: string;
  hash: string;
}

type FetchParams = Parameters<typeof fetch>[1];

const isJson = (value?: string) => {
  if (!value) return false;
  value = value.trim();
  const first = value[0];
  const last = value[value.length - 1];
  return (first === '{' && last === '}') || (first === '[' && last === ']');
};

export class Api {
  private _token?: string;
  constructor(public baseUrl: string) {
    this.register = this.register.bind(this);
    this.confirm = this.confirm.bind(this);
    this.login = this.login.bind(this);
    this.restore = this.restore.bind(this);
    this.restoreConfirm = this.restoreConfirm.bind(this);
    //
    this.getAuctions = this.getAuctions.bind(this);
    this.getDocuments = this.getDocuments.bind(this);
    this.getEntity = this.getEntity.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getWorks = this.getWorks.bind(this);
    //
    this.updateUser = this.updateUser.bind(this);
    this.updateEntity = this.updateEntity.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.approveDocuments = this.approveDocuments.bind(this);
    //
    this.postMessage = this.postMessage.bind(this);
    this.getMessages = this.getMessages.bind(this);
    this.getMessagesUpdates = this.getMessagesUpdates.bind(this);
    this.getReferenceDocuments = this.getReferenceDocuments.bind(this);
    //
    this.getTenderSchedules = this.getTenderSchedules.bind(this);
  }
  set token(value: string | undefined) {
    this._token = value;
  }
  //
  private async fetch<Response>(
    url: string,
    params?: FetchParams,
    asBlob?: boolean
  ) {
    let finalUrl = this.baseUrl + url;
    if (this._token) {
      if (!(params && params.method === 'POST')) {
        const token = `token=${this._token}`;
        const delimiter = finalUrl.indexOf('?') >= 0 ? '&' : '?';
        finalUrl += delimiter + token;
      }
    }
    const res = await fetch(finalUrl, params);
    const result = await this.readResult(res, res.ok ? asBlob : false);
    if (res.ok) return result as Response;
    else {
      const message =
        typeof result === 'string' ? result : JSON.stringify(result);
      if (message.indexOf('Время сессии истекло') >= 0) {
        localStorage.removeItem('gs.tenders.session');
        this._token = '';
        window.location.href = '/';
      }
      throw new Error(message);
    }
  }
  private async readResult(res: globalThis.Response, asBlob?: boolean) {
    const contentType = res.headers.get('content-type');
    if (asBlob) return res.blob();
    if (contentType && contentType.indexOf('json') >= 0) {
      return res.json();
    } else {
      const result = await res.text();
      // ну что ж за блядство такое, полчаса отладки на ровном месте
      if (isJson(result)) {
        try {
          return JSON.parse(result);
        } catch (e) {
          return result;
        }
      } else return result;
    }
  }
  //
  async register(payload: RegisterRequest) {
    this._token = '';
    await this.fetch<void>('/auth', {
      method: 'POST',
      body: JSON.stringify({
        type: 1,
        ...payload,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return true;
  }
  //
  async confirm(payload: ConfirmRequest) {
    this._token = '';
    await this.fetch('/auth', {
      method: 'POST',
      body: JSON.stringify({
        type: 2,
        ...payload,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return true;
  }
  //
  async login(payload: LoginRequest) {
    this._token = '';
    const result = await this.fetch<string>('/auth', {
      method: 'POST',
      body: JSON.stringify({
        type: 3,
        ...payload,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    this._token = result;
    return result;
  }
  //
  async restore(payload: RestoreRequest) {
    this._token = '';
    await this.fetch('/auth', {
      method: 'POST',
      body: JSON.stringify({
        type: 4,
        ...payload,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return true;
  }
  async restoreConfirm(payload: RestoreConfirmRequest) {
    this._token = '';
    await this.fetch('/auth', {
      method: 'POST',
      body: JSON.stringify({
        type: 5,
        ...payload,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return true;
  }
  //
  getUser() {
    return this.fetch<User>('/get?type=1');
  }
  //
  getEntity() {
    return this.fetch<Entity>(`/get?type=2`);
  }
  //
  getEntityByInn(inn: string) {
    return this.fetch<Entity>(`/get?type=2&inn=${inn}`);
  }
  //
  getWorks() {
    return this.fetch<Work[]>('/get?type=3');
  }
  //
  //
  getDocuments() {
    return this.fetch<Document[]>('/get?type=4');
  }
  //
  getAuctions() {
    return this.fetch<Auction[]>('/get?type=5');
  }
  //
  async updateUser(value: User) {
    const payload = { ...value } as User;
    delete payload.approval_ends;
    delete payload.approval_status;
    //
    await this.fetch('/post', {
      method: 'POST',
      body: JSON.stringify({
        type: 1,
        token: this._token,
        data: payload,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return true;
  }
  async updateEntity(value: UpdateEntityRequest) {
    const payload = {
      ...value,
    };
    await this.fetch('/post', {
      method: 'POST',
      body: JSON.stringify({
        type: 2,
        token: this._token,
        data: payload,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return true;
  }
  async uploadFile(value: UploadDocumentRequest) {
    const body = JSON.stringify({
      type: 3,
      token: this._token,
      data: [value],
    });
    await this.fetch('/post', {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return true;
  }
  downloadFile({ id }: DownloadDocumentRequest) {
    return this.fetch<DocumentFile>(`/get?type=6&file_id=${id}`, {
      method: 'GET',
    });
  }
  async approveDocuments(worksIds?: string[]) {
    await this.fetch('/post', {
      method: 'POST',
      body: JSON.stringify({
        type: 4,
        token: this._token,
        data: worksIds,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return true;
  }
  postMessage(request: PostMessageRequest) {
    const body = JSON.stringify({
      type: 5,
      token: this._token,
      data: request,
    });
    return this.fetch<PostMessageResponse>('/post', {
      method: 'POST',
      body,
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  getMessages() {
    return this.fetch<Message[]>('/get?type=7', {
      method: 'GET',
    });
  }
  getMessagesUpdates({ start_date, end_date }: UpdatesMessagesRequest) {
    return this.fetch<Message[]>(
      `/get?type=8&start_date=${start_date}&end_date=${end_date}`,
      {
        method: 'GET',
      }
    );
  }
  getReferenceDocuments() {
    return this.fetch<ReferenceDocument[]>('/get?type=9');
  }
  downloadFileAnonymous({ id }: DownloadDocumentRequest) {
    return this.fetch<DocumentFile>(`/get?type=10&file_id=${id}`, {
      method: 'GET',
    });
  }
  getTenderSchedules() {
    return this.fetch<TenderSchedule[]>('/get?type=11');
  }
}
