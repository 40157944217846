import { useCallback, useState } from 'react';
import { useContextBus } from 'shared/hooks/useContextBus';
import { api } from 'shared/api';
import { Context } from '../model';

export const encodeFile = (file: File) => {
  return new Promise<string | undefined>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      let result = reader.result?.toString();
      if (result) {
        if (result.startsWith('data:')) {
          const i = result.indexOf(',');
          if (i >= 0) {
            result = result.slice(i + 1);
          }
        }
      }
      resolve(result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};

export const useDocumentUpload = (id: string, onComplete?: () => void) => {
  const [loading, setLoading] = useState(false);
  const { post } = useContextBus(Context);
  const onUpload = useCallback(
    (file: File) => {
      setLoading(true);
      (async () => {
        try {
          const binary_data = await encodeFile(file);
          if (!!binary_data) {
            await api.uploadFile({ id, file_name: file.name, binary_data });
          }
          post('refresh');
          onComplete && onComplete();
          setLoading(false);
        } catch (err) {
          setLoading(false);
        }
      })();
    },
    [id, onComplete, post]
  );
  return {
    loading,
    onUpload,
  };
};
