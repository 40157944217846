import { useEffect, useMemo } from 'react';
import { api } from 'shared/api';
import { useQuery } from 'shared/hooks';

import { EMPTY_SLICE, type UserSlice } from '../model';

const data: unknown = undefined;

export const useUserQuery = () => {
  const { loading, value, perform } = useQuery(api.getUser);
  useEffect(() => {
    perform(data);
  }, [perform]);
  return useMemo(
    () => ({
      value: {
        loading,
        value: value || EMPTY_SLICE.value,
      } as UserSlice,
      refresh: perform,
    }),
    [loading, value, perform]
  );
};
