import { useCallback, useState } from 'react';
import { api } from 'shared/api';
import { saveAs } from 'shared/lib/saveAs';

export const useDocumentDownload = (id?: string, anonymous?: boolean) => {
  const [loading, setLoading] = useState(false);
  const onDownload = useCallback(() => {
    if (!id) return;
    setLoading(true);
    (async () => {
      try {
        if (anonymous) {
          const data = await api.downloadFileAnonymous({ id });
          await saveAs(data.data, data.name);
        } else {
          const data = await api.downloadFile({ id });
          await saveAs(data.data, data.name);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
    })();
  }, [id, anonymous]);
  return {
    loading,
    onDownload,
  };
};
