import { Routes, Route, Outlet, Navigate } from 'react-router-dom';

import { Login } from 'pages/Login';
import { Register } from 'pages/Register';
import { Restore } from 'pages/Restore';
import { RestoreConfirm } from 'pages/RestoreConfirm';
import { Confirmation } from 'pages/Confirmation';

import { Public as Layout } from '../layouts';

export const Public = () => {
  return (
    <Layout>
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route
          path="/confirmation/:validation_code?"
          element={<Confirmation />}
        />
        <Route path="/restore" element={<Outlet />}>
          <Route index element={<Restore />} />
          <Route path="confirm" element={<RestoreConfirm />} />
        </Route>
        <Route path="/" element={<Login />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  );
};
