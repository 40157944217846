import {
  type InputHTMLAttributes,
  type ReactNode,
  useCallback,
  useState,
} from 'react';
import clsx from 'clsx';

import { Input } from '../Input';
import { Typography } from '../Typography';
import { TextColor } from '../Typography/types';

import { Skeleton } from '../Skeleton';

import styles from './Field.module.css';

interface NativeFieldProps {
  loading?: boolean;
  caption?: string;
  error?: ReactNode;
  color?: TextColor;
}

type FieldProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'color'> &
  NativeFieldProps;

export const Field = ({
  className,
  loading,
  caption,
  color,
  error,
  readOnly,
  ...props
}: FieldProps) => {
  const hasValue = !!props.value;
  const [focused, setFocused] = useState(false);
  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);
  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);
  const filled = hasValue || focused || loading;
  return (
    <Typography.Label
      className={clsx(
        styles.container,
        loading ? styles.loading : null,
        className
      )}
      color={color}
    >
      <span className={clsx(styles.label, filled ? styles.filled : null)}>
        {caption}
      </span>
      <Input
        className={styles.input}
        color={error ? 'error' : color}
        readOnly={loading || readOnly}
        {...props}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <Skeleton className={styles.skeleton} active={loading} />
      <Typography.Text view="primary-small" color="error">
        {error}
      </Typography.Text>
    </Typography.Label>
  );
};
