import { useContext, useCallback } from 'react';
import { useContextBus } from 'shared/hooks/useContextBus';
import { Context, ChatSlice } from '../model';

export const useStore = <T>(
  selector: (state: ChatSlice) => T,
  equalityFn?: (a1: T, a2: T) => boolean
) => {
  const useStore = useContext(Context);
  return useStore(selector);
};

export const useRefresh = () => {
  const post = useContextBus(Context).post;
  return useCallback(() => {
    return post('refresh');
  }, [post]);
};

export const useMessages = () => {
  return useStore((state) => state.ids);
};

export const useMessage = (id: string) => {
  return useStore((state) => state.byId[id]);
};

export const useMessageExt = (id: string) => {
  const message = useMessage(id);
  return message;
};
