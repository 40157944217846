import { InputBar as Control } from 'features/Chat';
import { ChangeEventHandler, useCallback, useState } from 'react';

interface InputBarProps {
  className?: string;
}

export const InputBar = ({ className }: InputBarProps) => {
  const [value, setValue] = useState('');
  const handleChange = useCallback<ChangeEventHandler<HTMLTextAreaElement>>(
    (e) => {
      setValue(e.currentTarget.value);
    },
    []
  );
  const handleReset = useCallback(() => {
    setValue('');
  }, []);
  return (
    <Control
      className={className}
      value={value}
      onChange={handleChange}
      onReset={handleReset}
    />
  );
};
