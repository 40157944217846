import { useCallback } from 'react';
import { Editor as Form } from 'entities/Entity';
import { useEditor } from '../../hooks';
import { useRefresh } from 'entities/Entity';
import { useNavigate } from 'react-router-dom';

export const Editor = () => {
  const onRefresh = useRefresh();
  const navigate = useNavigate();
  const handleRefresh = useCallback(() => {
    onRefresh();
    navigate(-1);
  }, [onRefresh, navigate]);

  const { loading, value, onChange, onSubmit, error } =
    useEditor(handleRefresh);
  return (
    <Form
      error={error}
      loading={loading}
      value={value}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  );
};
