import { useMemo, type ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { Work } from 'shared/types';
import { ApprovalStatus, List, Skeleton, Typography } from 'shared/ui';

import styles from './Works.module.css';

interface WorksProps {
  loading: boolean;
  value?: Work[];
  action?: ReactNode;
}

const format = (value: string) =>
  value.split('T')[0].split('-').reverse().join('.');

export const Works = ({ loading, value, action }: WorksProps) => {
  const items = useMemo(
    () =>
      value
        ? value
            .filter((item) => item.selected)
            .map((item) => ({
              name: item.name,
              status: item.approval_status,
              approval_ends: item.approval_ends,
            }))
        : undefined,
    [value]
  );
  const loadingItems = useMemo(() => {
    if (!loading) return;
    return [20, 18, 25, 10];
  }, [loading]);
  const isEmpty = useMemo(() => {
    return !items || items.length === 0;
  }, [items]);
  const children = useMemo(() => {
    if (loading)
      return loadingItems?.map((item, i) => (
        <div className={styles.item} key={i}>
          <Skeleton active width={item}>
            xx
          </Skeleton>
        </div>
      ));
    return items?.map((item, i) => (
      <Link to="/entity/documents/" className={styles.item} key={i}>
        <Typography.Text size="large">{item.name}</Typography.Text>
        <Typography.Text size="medium">
          <ApprovalStatus
            before={item.approval_ends ? format(item.approval_ends) : undefined}
          >
            {item.status}
          </ApprovalStatus>
        </Typography.Text>
      </Link>
    ));
  }, [loading, items, loadingItems]);
  return (
    <>
      <List className={styles.list}>
        {isEmpty && !loading ? (
          <Typography.Text>Нет выбранных работ</Typography.Text>
        ) : null}
        {children}
      </List>
      <div className={styles.actions}>{action}</div>
    </>
  );
};
