export const saveAs = async (data: string, fileName: string) => {
  const res = await fetch('data:application/octet-stream;base64,' + data);
  const blob = new Blob([new Uint8Array(await res.arrayBuffer())]);
  const url = URL.createObjectURL(blob);
  try {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
    await new Promise((resolve) => setTimeout(resolve, 25));
    link.remove();
  } finally {
    URL.revokeObjectURL(url);
  }
};
